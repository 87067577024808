import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import {
  Container,
  Button,
  UncontrolledTooltip,
  Row,
  Col,
  Form
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPortrait,
  faPlus,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import ReactTable from "react-table";
import ClubSelectList from "../../containers/clubSelectList";
import SelectFilter from "../common/SelectFilter";

// TODO: REDUX
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";

interface Props {
  users: QueryResponse<User>;
  searching: boolean;
  onClickNewUser: (user?) => any;
  reloadUsers: boolean;
  getUsers: (params: any) => void;
  history: any;
  roles: any[];
  positions: any[];
}

interface State {
  name: string;
  surename: string;
  username: string;
  numeroEmpleado: string;
  idClub: number;
  status: any;
  rol: any;
  puesto: any;
  club: any;
}

interface User {
  id: string;
  nombre: string;
}

const emptyStatus = {
  value: "",
  label: "Seleccione un estatus"
};

const emptyRol = {
  value: "",
  label: "Seleccione un rol"
};

const emptyPuesto = {
  value: "",
  label: "Seleccione un puesto"
};

class Users extends React.Component<Props, State> {
  state = {
    name: "",
    surename: "",
    username: "",
    numeroEmpleado: "",
    idClub: null,
    status: emptyStatus,
    rol: emptyRol,
    puesto: emptyPuesto,
    club: null
  };

  handleEditUser = user => {
    this.props.onClickNewUser(user);
  };

  handleDeleteUser = user => {
    const id = user.userId;
    alert(`Eliminar el usuario con el id ${id}`);
  };

  handleNameChanged = e => {
    const name = e.target.value;
    this.setState({ name });
  };

  handleSurnameChanged = e => {
    const surename = e.target.value;
    this.setState({ surename });
  };

  handleUsernameChanged = e => {
    const username = e.target.value;
    this.setState({ username });
  };

  handleNumeroEmpleadoChanged = e => {
    const numeroEmpleado = e.target.value;
    this.setState({ numeroEmpleado });
  };

  onFecthData = ({ pageSize: size, page }) => {
    if (!!this.props.getUsers) {
      const params = { size, page } as any;
      const {
        name,
        surename,
        username,
        idClub,
        status,
        numeroEmpleado,
        rol,
        puesto
      } = this.state;

      if (!!name) {
        params.nombre = name;
      }
      if (!!surename) {
        params.apellidoPaterno = surename;
      }
      if (!!username) {
        params.usuario = username;
      }
      if (!!numeroEmpleado && numeroEmpleado.trim()) {
        params.numeroEmpleado = numeroEmpleado.trim();
      }
      if (!!idClub) {
        params.idClub = idClub;
      }
      if (status && status.value) {
        params.status = status.value;
      }
      if (rol && rol.value) {
        params.rol = rol.value;
      }
      if (puesto && puesto.value) {
        params.puesto = puesto.value;
      }
      this.props.getUsers(params);
    }
  };

  handleSearchClicked = () => {
    this.onFecthData({ pageSize: 20, page: 0 });
  };

  render() {
    const columns = [
      {
        Header: "No. Empleado",
        width: 100,
        Cell: row => row.original.employeeNumber || ""
      },
      {
        Header: "Nombre",
        Cell: row => {
          return (
            (row.original.name || "") +
            " " +
            (row.original.firstSurname || "") +
            " " +
            (row.original.secondSurname || "")
          );
        }
      },
      {
        Header: "Usuario",
        accessor: "username"
      },
      {
        Header: "Clubes",
        Cell: row => {
          const clubs = row.original.clubs || [];
          return clubs.map(s => s.externalName).join(", ");
        }
      },
      {
        Header: "Rol",
        Cell: row => {
          const roles = row.original.roles || [];
          return roles.map(s => s.description).join(", ");
        }
      },
      {
        Header: "Puesto",
        Cell: row => {
          const positions = row.original.positions || [];
          return positions.map(s => s.name).join(", ");
        }
      },
      {
        Header: "Estatus",
        width: 100,
        id: "status",
        accessor: item => {
          if (item.status === "ACTIVE") {
            return (
              <>
                <a
                  style={{
                    color: "#0073e6",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  id={"activar-" + item.userId}
                  onClick={async () => {
                    try {
                      await axios.put(
                        `${constants.BASE_URL}/api/auth/deactivate/${item.userId}`,
                        null,
                        {
                          withCredentials: true
                        }
                      );

                      successNotification("Desactivando usuario");
                      item.status = "SUSPENDED";
                      this.forceUpdate();
                    } catch (error) {
                      errorNotification("No se pudo desactivar el usuario");
                    }
                  }}
                >
                  Activo
                </a>
              </>
            );
          } else {
            return (
              <>
                <a
                  style={{
                    color: "#0073e6",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  id={"activar-" + item.userId}
                  onClick={async () => {
                    try {
                      await axios.put(
                        `${constants.BASE_URL}/api/auth/active/${item.userId}`,
                        null,
                        {
                          withCredentials: true
                        }
                      );

                      successNotification("Reactivando usuario");
                      item.status = "ACTIVE";
                      this.forceUpdate();
                    } catch (error) {
                      errorNotification("No se pudo activar el usuario");
                    }
                  }}
                >
                  Inactivo
                </a>
              </>
            );
          }
        }
      },
      {
        Header: "Fecha baja",
        width: 100,
        Cell: row => row.original.fechaBajaUsuario || ""
      },
      {
        Header: "Rec. facial",
        width: 100,
        Cell: row => (
          <>
            <div
              onClick={() => {
                const emp = row.original.employeeNumber;

                // FIXME: Consultar la ruta con Daniel Muñoz
                console.error("Not implemented yet " + emp);
                /*window.open(
                `https://cloud2.sportsworld.com.mx/apiMonitor/fotografia/crm2/home/${emp}`
              );*/
              }}
              id={"biometric-" + row.original.userId}
              style={{
                color: "#4da6ff",
                textAlign: "center",
                fontSize: "1.4em",
                cursor: "pointer"
              }}
            >
              <FontAwesomeIcon icon={faPortrait} color={"#4da6ff"} />
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={"biometric-" + row.original.userId}
            >
              Reconocimiento facial
            </UncontrolledTooltip>
          </>
        )
      },
      {
        Header: () => (
          <div className="w-100 text-center">
            <Button
              color="link"
              onClick={() => this.props.onClickNewUser()}
              className={`${s.actionHeaderIcon}`}
            >
              <FontAwesomeIcon
                style={{ fontSize: "15px !important" }}
                icon={faPlus}
              />
            </Button>
          </div>
        ),
        width: 48,
        id: "buttons",
        accessor: item =>
          item.status != "ACTIVE" ? null : (
            <Col
              key={`editAction${item.userId}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`editAction${item.userId}`}
                onClick={() => this.handleEditUser(item)}
                className={`${s.editAnchor}`}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "15px !important", cursor: "pointer" }}
                  icon={faPencilAlt}
                />
              </a>
              <UncontrolledTooltip
                placement="bottom"
                target={`editAction${item.userId}`}
              >
                Editar
              </UncontrolledTooltip>
            </Col>
          )
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.handleSearchClicked();
          }}
        >
          <div className="row mb-3">
            <div className="col-sm-3">
              <ClubSelectList
                clubSelected={this.state.club}
                onSelectClub={club =>
                  this.setState({ club, idClub: club ? club.value : null })
                }
                label="Club"
              />
            </div>
            <div className="col-sm-3">
              <BaseInput
                label="Nombre"
                id="userFilterName"
                name="userFilterName"
                type="text"
                placeholder="Nombre"
                value={this.state.name}
                onChange={this.handleNameChanged}
              />
            </div>
            <div className="col-sm-3">
              <BaseInput
                label="Usuario"
                id="userFilterUserName"
                name="userFilterUserName"
                type="text"
                placeholder="Usuario"
                value={this.state.username}
                onChange={this.handleUsernameChanged}
              />
            </div>
            <div className="col-sm-3">
              <BaseInput
                label="No. empleado"
                id="userFilterNoEmpleado"
                name="userFilterNoEmpleado"
                type="text"
                placeholder="No. empleado"
                value={this.state.numeroEmpleado}
                onChange={this.handleNumeroEmpleadoChanged}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-sm-3">
              <SelectFilter
                label="Estatus del usuario"
                name="status"
                type="select"
                id="status"
                placeholder="Estatus"
                value={this.state.status}
                isMulti={false}
                options={[
                  emptyStatus,
                  {
                    value: "ACTIVE",
                    label: "Activo"
                  },
                  {
                    value: "SUSPENDED",
                    label: "Inactivo"
                  }
                ]}
                onChange={status => this.setState({ status })}
                loading={false}
                isAsync={false}
              />
            </div>
            <div className="col-sm-3">
              <SelectFilter
                label="Rol"
                name="rol"
                type="select"
                id="rol"
                placeholder="Rol"
                value={this.state.rol}
                isMulti={false}
                disabled={!this.props.roles}
                options={[
                  emptyRol,
                  ...(this.props.roles || []).map(rol => ({
                    value: rol.id,
                    label: rol.description
                  }))
                ]}
                onChange={rol => this.setState({ rol })}
                loading={false}
                isAsync={false}
              />
            </div>
            <div className="col-sm-3">
              <SelectFilter
                label="Puesto"
                name="puesto"
                type="select"
                id="puesto"
                placeholder="Puesto"
                value={this.state.puesto}
                isMulti={false}
                disabled={!this.props.positions}
                options={[
                  emptyPuesto,
                  ...(this.props.positions || []).map(puesto => ({
                    value: puesto.positionId,
                    label: puesto.name
                  }))
                ]}
                onChange={puesto => this.setState({ puesto })}
                loading={false}
                isAsync={false}
              />
            </div>

            <div className="col-sm-3 pt-3">
              <Button
                className={`${s.searchButton} ${s.primaryButton}`}
                type="submit"
              >
                Buscar
              </Button>
            </div>
          </div>
        </Form>
        <Row>
          <Col>
            <ReactTable
              manual
              data={this.props.users.content}
              pages={this.props.users.totalPages}
              columns={columns}
              className="w-100"
              onFetchData={this.onFecthData}
              defaultPageSize={20}
              loading={this.props.searching}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Users));
