import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  onKeyPress: (target: any) => void;
  onBlur: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  options: any;
  className?: string;
  uppercase: boolean;
  maxlength: string;
}

interface State {
  passwordShown: boolean;
}

class BasePasswordInput extends React.Component<Props, State> {
  state = {
    passwordShown: false
  };

  render() {
    const {
      id,
      name,
      value,
      label,
      placeholder,
      dataQAId,
      dataElmId,
      children,
      errors,
      disabled,
      size,
      maxlength
    } = this.props;

    let errClass = errors && errors.length ? s.error : "";

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <br />
        <div className={`${s.ptSiteFooterSubmit}`}>
          <Input
            type={this.state.passwordShown ? "text" : "password"}
            name={name}
            id={id}
            placeholder={placeholder}
            className={`${s.input} ${errClass} px-2 py-1 ${
              !!this.props.uppercase ? "text-uppercase" : ""
            }`}
            value={value}
            disabled={disabled || false}
            data-qa-id={dataQAId}
            data-elm-id={dataElmId}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
            onKeyPress={this.props.onKeyPress}
            size={size || "md"}
            maxLength={maxlength || ""}
          ></Input>
          <button
            onClick={() =>
              this.setState({ passwordShown: !this.state.passwordShown })
            }
            type="button"
          >
            {this.state.passwordShown ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </button>
        </div>
        {children}
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(BasePasswordInput);
