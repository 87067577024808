import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import ProspectoCrm from "../../types/ProspectoCrm";

const PROSPECTOS_BASE_URL = "/api/prospects";

// Limpieza de prospectos
export const clearProspectoCrm = (): IAction => {
  return {
    type: actionTypes.CLEAR_PROSPECTO_CRM,
    payload: true
  };
};

export const getProspectoCrmParams = (): IAction => {
  return prospectosRestAction({
    actionType: actionTypes.GET_PROSPECTO_CRM_PARAMS,
    urlComplement: `/crm/params`,
    method: "get"
  });
};

export const getProspectoCrmRenapo = (prospecto: ProspectoCrm): IAction => {
  return prospectosRestAction({
    actionType: actionTypes.GET_PROSPECTO_CRM_RENAPO,
    urlComplement: `/crm/renapo`,
    body: prospecto,
    method: "post"
  });
};

export const postProspectoCrm = (prospecto: ProspectoCrm): IAction => {
  return prospectosRestAction({
    actionType: actionTypes.POST_PROSPECTO_CRM,
    urlComplement: `/crm`,
    method: "post",
    body: prospecto
  });
};

export const getProspectosCrmRepetidosCurp = (curp: string): IAction => {
  return prospectosRestAction({
    actionType: actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CURP,
    urlComplement: `/crm/repetidos-curp`,
    method: "get",
    params: { curp }
  });
};

export const getProspectosCrmRepetidosCorreo = (correo: string): IAction => {
  return prospectosRestAction({
    actionType: actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CORREO,
    urlComplement: `/crm/repetidos-correo`,
    method: "get",
    params: { correo }
  });
};

// Construye una instancia de RestAction para Redux
const prospectosRestAction = restActionFactory(
  `${constants.BASE_URL}${PROSPECTOS_BASE_URL}`
);
