import React from "react";
import { connect } from "react-redux";
import { editProspect } from "../../../Home/actions/prospects";
import { withRouter } from "react-router-dom";
import PeopleBasicData from "../../components/PeopleBasicData";
import { Person } from "../../../Home/components/oneDayPassActivatorPage/interfaces";
import {
  getCurpData,
  getPersonasRepetidasCurp
} from "../../../Home/actions/persons";

interface Props {
  viewMode: boolean;
  prospectToEdit: Person;
  createProspect: (prospect: object) => any;
  history: any;
  match: any;
  editProspect: (personaId: string, prospect: any) => any;
  userId: string;
  creatingProspect: any;
  creatingProspectError: any;
  creatingProspectValues: any;
  onChange: (prospect: object) => any;
  disableDetailsButton: boolean;
  small: boolean;
  isConcesionario: boolean;
  isCorporativo: boolean;
  isProspecto: boolean;
  isMoralPersonType: boolean;
  empresasConvenio: any[];
  validateEmail: (correo: string) => void;
  getCurpData: (body: object) => any;
  getPersonasRepetidasCurp: (curp: string) => any;
}
interface State {}

class PeopleBasicDataContainer extends React.Component<Props, State> {
  render() {
    return (
      <PeopleBasicData
        viewMode={this.props.viewMode}
        prospectToEdit={this.props.prospectToEdit}
        createProspect={this.props.createProspect}
        editProspect={this.props.editProspect}
        userId={this.props.userId}
        creatingProspect={this.props.creatingProspect}
        creatingProspectError={this.props.creatingProspectError}
        creatingProspectValues={this.props.creatingProspectValues}
        onChange={this.props.onChange}
        disableDetailsButton={this.props.disableDetailsButton}
        small={this.props.small}
        isConcesionario={this.props.isConcesionario}
        isCorporativo={this.props.isCorporativo}
        isProspecto={this.props.isProspecto}
        isMoralPersonType={this.props.isMoralPersonType}
        empresasConvenio={this.props.empresasConvenio}
        validateEmail={this.props.validateEmail}
        getCurpData={this.props.getCurpData}
        getPersonasRepetidasCurp={this.props.getPersonasRepetidasCurp}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  editProspect: (personaId, prospect) =>
    dispatch(editProspect(personaId, prospect)),
  getCurpData: (body: object) => dispatch(getCurpData(body)),
  getPersonasRepetidasCurp: (curp: string) =>
    dispatch(getPersonasRepetidasCurp(curp))
});

const mapStateToProps = state => ({
  creatingProspect: state.prospects.creatingProspect,
  creatingProspectError: state.prospects.creatingProspectError,
  creatingProspectValues: state.prospects.creatingProspectValues
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PeopleBasicDataContainer)
);
