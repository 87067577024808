import React, { Component } from "react";
import { RouterProps } from "../../../toolkit/decorators/Artifact";
import { ProspectoCrmModalDispatcher } from "../../containers/prospectoCrmModal/ProspectoCrmModal.dispatcher";
import { ProspectoCrmModalStore } from "../../containers/prospectoCrmModal/ProspectoCrmModal.store";
import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import {
  Inputs,
  Buttons,
  formConfigFactory,
  FormValue
} from "./ProspectoCrmModal.form";
import ProspectoCrm from "../../../types/ProspectoCrm";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import { errorNotification } from "../../../utils/notifications";
import { commonRegex } from "../../helpers/commonRegex";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IDateBuilder from "../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import { parseEmpresaConvenio } from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelector/IEmpresaConvenioSelectorConnected";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ProspectoCrmModalRepetidos from "./ProspectoCrmModalRepetidos.component";

interface Props
  extends ProspectoCrmModalStore,
    ProspectoCrmModalDispatcher,
    RouterProps<any> {
  openModal?: boolean;
  onCloseModal?: () => void;
  onProspectSaved?: (prospect: ProspectoCrm) => void;
}

interface State {
  prospecto: ProspectoCrm;
  formValues: (values: { [key in Inputs]?: any }) => void;
  formConfig: FormBuilderConfig<Inputs, Buttons>;
}

class ProspectoCrmModalComponent extends Component<Props, State> {
  state = {
    prospecto: {} as ProspectoCrm,
    formValues: null as State["formValues"],
    formConfig: null as State["formConfig"]
  };

  async componentDidMount() {
    // Limpieza de los campos
    await this.props.clearProspectoCrm();

    // Se detremina si se debe solicitar la Curp
    const preview = await this.props.getProspectoCrmParams();
    const curpRequired = !preview.prospectoCrmParams.omitirCurp;
    const curpApiActiva = preview.prospectoCrmParams.apiCurpCrm;

    // Se fabrica el formulario, dependiendo de los permisos
    this.setState({
      formConfig: formConfigFactory(curpRequired, curpApiActiva)
    });
  }

  onSubmit = async (values: FormValue) => {
    const prospectoForm = {
      ...this.state.prospecto,
      ...values
    } as ProspectoCrm & FormValue;

    // Formato de valores del formulario (el convenio se forma de dos id's)
    const convenioParsed = parseEmpresaConvenio(prospectoForm.convenio);
    prospectoForm.idConvenio = convenioParsed.idConvenio;
    prospectoForm.idEmpresa = convenioParsed.idEmpresa;
    prospectoForm.esExtranjero = prospectoForm.esExtranjero === "1";
    delete prospectoForm.convenio;
    delete prospectoForm.alert;

    // Se guarda al prospecto y se cierra la pantalla
    const preview = await this.props.postProspectoCrm(prospectoForm);
    if (preview.fulfilled) {
      this.props.onProspectSaved(preview.prospectoCrm);
      this.props.onCloseModal();
    } else {
      this.props.getProspectosCrmRepetidosCurp(values.curp);
      this.props.getProspectosCrmRepetidosCorreo(values.correo);
      this.setState({
        prospecto: {
          ...prospectoForm
        }
      });
      errorNotification(
        preview.postingProspectoCrmError ||
          "Ocurrió un error al guardar el prospecto"
      );
    }
  };

  onClick = async (values: FormValue) => {
    // Validación previa de la Curp
    if (!values.curp) {
      errorNotification("Debe ingresar la CURP");
      return;
    } else if (!commonRegex.curp.test(values.curp.toUpperCase())) {
      errorNotification("La CURP ingresada no es válida");
      return;
    }

    // Consulta de los datos de la Curp en la Api de Renapo
    this.props.getProspectosCrmRepetidosCurp(values.curp);
    const preview = await this.props.getProspectoCrmRenapo({
      curpAprobada: !!this.state.prospecto.curpAprobada,
      curp: values.curp,
      apiCurpEstatus: this.state.prospecto.apiCurpEstatus
    } as ProspectoCrm);

    if (!preview.fulfilled) {
      errorNotification("Ocurrió un error al verificar la CURP");
      return;
    }

    // Resultado de la consulta en el State, obtenidas de la consulta en la Curp
    const prospecto = preview.prospectoCrmRenapo;
    this.setState({
      prospecto: {
        ...this.state.prospecto,
        ...prospecto
      }
    });

    if (prospecto.curpAprobada) {
      // Se establecen los valores de la Curp en el formulario
      this.state.formValues({
        curp: prospecto.curp,
        nombre: prospecto.nombre,
        primerApellido: prospecto.primerApellido,
        segundoApellido: prospecto.segundoApellido,
        sexo: prospecto.sexo,
        fechaNacimiento: prospecto.fechaNacimiento
      });
    } else {
      errorNotification(
        (prospecto && prospecto.apiCurpError) || "No se pudo obtener la CURP"
      );
    }
  };

  onBlurCorreo = (evt: any) => {
    if (!evt.target.value) {
      return;
    }
    this.props.getProspectosCrmRepetidosCorreo(evt.target.value);
  };

  onBlurCurp = (evt: any) => {
    // "Limpia" los Curps repetidos, si se puede omitir la Curp
    if (!evt.target.value && this.props.prospectoCrmParams.omitirCurp) {
      this.props.getProspectosCrmRepetidosCurp("");
    }
  };

  render() {
    if (!this.state.formConfig) {
      return (
        <div style={{ textAlign: "center", color: "#999", fontSize: "24px" }}>
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      );
    }

    // La aprobación de la Curp se requiere cuando esta no se hubiese obtenido;
    // además, la Api de Renapo debe estar activa y disponible.
    const curpAprobada = !!this.state.prospecto.curpAprobada;
    const curpApiActiva = !!this.props.prospectoCrmParams.apiCurpCrm;
    const curpApiDisponible = this.state.prospecto.apiCurpEstatus !== "ERROR";
    const curpRequerida = !this.props.prospectoCrmParams.omitirCurp;
    const curpRequiereAprobacion =
      !curpAprobada && curpApiActiva && curpApiDisponible;

    // Se determina el label del botón verificarCurp
    let verificarCurpLabel = "Verificar CURP";
    if (curpAprobada) {
      verificarCurpLabel = "¡CURP Aprobada!";
    } else if (!curpRequiereAprobacion) {
      verificarCurpLabel = "CURP verificada";
    }

    // Clientes repetidos (podrían ser prospectos)
    const repetidosCorreo = this.props.prospectosCrmRepetidosCorreo;
    const repetidosCurp = this.props.prospectosCrmRepetidosCurp;
    const repetidos = repetidosCurp || repetidosCorreo || [];
    const error = !!repetidosCurp;

    return (
      <div style={{ padding: "0 30px" }}>
        {/* Componente de prospectos repetidos, cuando se requieran */}
        <ProspectoCrmModalRepetidos
          repetidos={repetidos}
          error={error}
          onClick={repetido => {
            window.open(`/productos/carrito/${repetido.idPersona}`, "_blank");
          }}
        />

        {/* Formulario de captura */}
        <FormBuilder<Inputs, Buttons>
          config={this.state.formConfig}
          submit={this.onSubmit}
          triggerFormValues={formValues => this.setState({ formValues })}
          processing={
            this.props.gettingProspectoCrmRenapo ||
            this.props.postingProspectoCrm ||
            this.props.gettingProspectosCrmRepetidosCorreo ||
            this.props.gettingProspectosCrmRepetidosCurp
          }
        >
          {/* Tratamiento del campo de la Curp */}
          <ITextBuilder
            name="curp"
            disabled={curpAprobada || !curpApiDisponible}
            onBlur={this.onBlurCurp}
          />

          {/* Deshabilitado de campos dependientes de la Curp */}
          <ITextBuilder disabled={curpAprobada} name="nombre" />
          <ITextBuilder disabled={curpAprobada} name="primerApellido" />
          <ITextBuilder disabled={curpAprobada} name="segundoApellido" />
          <IDateBuilder disabled={curpAprobada} name="fechaNacimiento" />
          <ISelectBuilder disabled={curpAprobada} name="sexo" />

          {/* Obtención de clientes repetidos por correo */}
          <ITextBuilder name="correo" onBlur={this.onBlurCorreo} />

          <IButtonBuilder
            name="verificarCurp"
            label={verificarCurpLabel}
            onClick={this.onClick}
            hidden={!curpApiActiva}
            disabled={values => {
              const curp: string = values.curp;
              return (
                !curpRequiereAprobacion ||
                !curp ||
                curp.length != 18 ||
                !commonRegex.curp.test(curp.toUpperCase())
              );
            }}
          />
          <IButtonBuilder
            name="guardar"
            disabled={curpRequerida && curpRequiereAprobacion}
          />

          {/* Error de fomulario */}
          {!!this.props.postingProspectoCrmError && (
            <div data-form-name="alert" className="col m-3 alert alert-danger">
              {this.props.postingProspectoCrmError}
            </div>
          )}
        </FormBuilder>
      </div>
    );
  }
}

export default ProspectoCrmModalComponent;
