import { Profile } from "./Profile";

export interface User {
  userId?: number;
  username: string;
  domain: string;
  groups: string[];
  status: string;
  profile: Profile;
}

export interface UserAccount {
  account: User;
  profile: Profile;
}

export enum MedioProspeccion {
  ACTIVACIONES_VALLA_MOVIL = "ACTIVACIONES_VALLA_MOVIL",
  AMIGO_REFERIDO = "AMIGO_REFERIDO",
  CITA_CONTACT_CENTER = "CITA_CONTACT_CENTER",
  EVENTOS_ESPECIALES = "EVENTOS_ESPECIALES",
  EX_CLIENTE = "EX_CLIENTE",
  LLAMADA_ENTRANTE = "LLAMADA_ENTRANTE",
  LEAD_DIGITAL = "LEAD_DIGITAL",
  MEDIOS_IMPRESOS = "MEDIOS_IMPRESOS",
  PAGINA_WEB_SW = "PAGINA_WEB_SW",
  WALKING_ESPONTANEO = "WALKING_ESPONTANEO"
}

export interface Prospect {
  correoElectronico: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: string;
  medioProspeccion: MedioProspeccion;
  codigoPostal: string;
  colonia: string;
  sexo: string;
  numeroTelefono: string;
  extension: string;
  folio: string;
  precioMembresia: number;
  mantenimiento: string;
  precioMantenimiento: number;
  curpAprobada: boolean;
}

export enum INTEREST_STATUS {
  STATUS_INTERESADO = "INTERESADO",
  STATUS_NO_INTERESADO = "NO_INTERESADO",
  STATUS_POSIBLEMENTE = "POSIBLEMENTE"
}

export const DISINTEREST_REASON = [
  {
    value: "PRECIO",
    label: "Precio"
  },
  {
    value: "OFERTA_DEPORTIVA",
    label: "Oferta deportiva"
  },
  {
    value: "MANTENIMIENTO_INSTALACIONES",
    label: "Mantenimiento instalaciones"
  },
  {
    value: "LIMPIEZA",
    label: "Limpieza"
  },
  {
    value: "MIEDO_CONTAGIO",
    label: "Miedo al contagio"
  },
  {
    value: "PROTOCOLO_SANIDAD",
    label: "Protocolo de sanidad"
  },
  {
    value: "INCERTIDUMBRE_SALARIAL",
    label: "Incertidumbre de sueldo"
  },
  {
    value: "ESTACIONAMIENTO",
    label: "Estacionamiento"
  },
  {
    value: "DISTANCIA",
    label: "Distancia del club"
  }
];
