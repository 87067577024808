import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";

export type Inputs = "idProducto" | "nombre" | "clave";
export type Buttons = "buscar";

export const formularioBusquedaProductosSat: FormBuilderConfig<
  Inputs,
  Buttons
> = {
  inputs: [
    {
      name: "idProducto",
      label: "Id del producto",
      bts: {
        mCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "nombre",
      label: "Nombre del producto",
      props: {
        uppercase: true
      },
      bts: {
        mCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "clave",
      label: "Clave",
      props: {
        uppercase: true
      },
      bts: {
        mCol: 4
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        mOff: 9,
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  rows: [["idProducto", "nombre", "clave"], ["buscar"]]
};
