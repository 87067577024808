import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/productosSat";
import {
  CrearProductoSat,
  ActualizarProductoSat,
  GetProductosSat
} from "../../reducers/productosSat";

export interface ProductosSatDispatcher {
  getProductosSatActivos: () => Promise<Preview<GetProductosSat>>;
  getProductosSatActivosCustom: (
    id: number,
    nombre: string,
    clave: string
  ) => Promise<Preview<GetProductosSat>>;
  crearProductoSat: (
    body: CrearProductoSat
  ) => Promise<Preview<GetProductosSat>>;
  actualizarProductoSat: (
    body: ActualizarProductoSat
  ) => Promise<Preview<GetProductosSat>>;
  desactivarProductoSAT: (id: number) => Promise<Preview<any>>;
  getProductosNombreClaveDuplicados: (
    clave: string
  ) => Promise<Preview<GetProductosSat>>;
  getProductosNombreDuplicados: (
    nombre: string
  ) => Promise<Preview<GetProductosSat>>;
}

export const productosSatDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ProductosSatDispatcher = dispatch => ({
  getProductosSatActivos: () => {
    return dispatch(actions.getProductosSatActivos());
  },
  getProductosSatActivosCustom: (id: number, nombre: string, clave: string) => {
    return dispatch(actions.getProductosSatActivosCustom(id, nombre, clave));
  },
  crearProductoSat: (body: CrearProductoSat) => {
    return dispatch(actions.crearProductoSat(body));
  },
  actualizarProductoSat: (body: ActualizarProductoSat) => {
    return dispatch(actions.actualizarProductoSat(body));
  },
  desactivarProductoSAT: (id: number) => {
    return dispatch(actions.desactivarProductoSAT(id));
  },
  getProductosNombreClaveDuplicados: (clave: string) => {
    return dispatch(actions.getProductosNombreClaveDuplicados(clave));
  },
  getProductosNombreDuplicados: (nombre: string) => {
    return dispatch(actions.getProductosNombreDuplicados(nombre));
  }
});
