import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Input } from "reactstrap";
import s from "./styles.scss";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  onBlur: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  className?: string;
  uppercase: boolean;
  maxlength: string;
}

class AlphanumericInput extends React.Component<Props, void> {
  render() {
    const {
      id,
      name,
      value,
      label,
      placeholder,
      dataQAId,
      dataElmId,
      errors,
      disabled,
      size,
      maxlength
    } = this.props;

    const specialCharCodes = [8, 9, 32];

    return (
      <Input
        type="text"
        name={name}
        id={id}
        placeholder={placeholder}
        className={`${!!this.props.uppercase ? "text-uppercase" : ""}`}
        value={value}
        disabled={disabled || false}
        data-qa-id={dataQAId}
        data-elm-id={dataElmId}
        onKeyPress={event => {
          if (
            !(
              (event.charCode >= 48 && event.charCode <= 57) ||
              (event.charCode >= 65 && event.charCode <= 90) ||
              (event.charCode >= 97 && event.charCode <= 122) ||
              specialCharCodes.indexOf(event.charCode) >= 0
            )
          ) {
            event.preventDefault();
          }
        }}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        size={size || "md"}
        maxlength={maxlength || 1000}
      />
    );
  }
}

export default withStyles(s)(AlphanumericInput);
