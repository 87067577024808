import {
  GetProspectoCrmParams,
  GetProspectoCrmRenapo,
  PostProspectoCrm,
  GetProspectosCrmRepetidosCurp,
  GetProspectosCrmRepetidosCorreo
} from "../../reducers/prospectosV2";

export interface ProspectoCrmModalStore
  extends GetProspectoCrmParams,
    GetProspectoCrmRenapo,
    PostProspectoCrm,
    GetProspectosCrmRepetidosCurp,
    GetProspectosCrmRepetidosCorreo {}

export const prospectoCrmModalStore: (
  state
) => ProspectoCrmModalStore = state => {
  const { prospectosV2 } = state;

  return {
    prospectoCrmParams: prospectosV2.prospectoCrmParams,
    hasProspectoCrmParams: prospectosV2.hasProspectoCrmParams,
    gettingProspectoCrmParams: prospectosV2.gettingProspectoCrmParams,
    gettingProspectoCrmParamsError: prospectosV2.gettingProspectoCrmParamsError,

    prospectoCrmRenapo: prospectosV2.prospectoCrmRenapo,
    gettingProspectoCrmRenapo: prospectosV2.gettingProspectoCrmRenapo,
    gettingProspectoCrmRenapoError: prospectosV2.gettingProspectoCrmRenapoError,

    prospectoCrm: prospectosV2.prospectoCrm,
    postingProspectoCrm: prospectosV2.postingProspectoCrm,
    postingProspectoCrmError: prospectosV2.postingProspectoCrmError,

    prospectosCrmRepetidosCurp: prospectosV2.prospectosCrmRepetidosCurp,
    gettingProspectosCrmRepetidosCurp:
      prospectosV2.gettingProspectosCrmRepetidosCurp,
    gettingProspectosCrmRepetidosCurpError:
      prospectosV2.gettingProspectosCrmRepetidosCurpError,

    prospectosCrmRepetidosCorreo: prospectosV2.prospectosCrmRepetidosCorreo,
    gettingProspectosCrmRepetidosCorreo:
      prospectosV2.gettingProspectosCrmRepetidosCorreo,
    gettingProspectosCrmRepetidosCorreoError:
      prospectosV2.gettingProspectosCrmRepetidosCorreoError
  };
};
