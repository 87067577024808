import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import {
  formConfig,
  Inputs,
  Buttons,
  FormValue
} from "./ProductoSatModal.form";
import ProductoSat from "../../../types/ProductoSat";
import { ProductosSatDispatcher } from "../../containers/productosSat/productosSat.dispatcher";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import ProductosDuplicadosModal from "./ProductosDuplicadosModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  dispatcher: ProductosSatDispatcher;
  producto?: ProductoSat;
  onProductoSaved?: () => void;
  saving: boolean;
}

interface State {
  duplicadosNombre: ProductoSat[];
  duplicadosClave: ProductoSat[];
}

export default class ProductoSatModal extends Component<Props, State> {
  state = {
    duplicadosNombre: [],
    duplicadosClave: []
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen && !this.props.isOpen) {
      this.setState({
        duplicadosNombre: [],
        duplicadosClave: []
      });
    }
  }

  buscarDuplicadosPorNombre = async (
    nombre: string
  ): Promise<ProductoSat[]> => {
    if (!nombre) return [];

    try {
      const result = await this.props.dispatcher.getProductosNombreDuplicados(
        nombre
      );

      return (result.productosSat || []).filter(
        producto =>
          !this.props.producto || producto.id !== this.props.producto.id
      );
    } catch (error) {
      console.error("Error buscando duplicados por nombre:", error);
      return [];
    }
  };

  buscarDuplicadosPorClave = async (clave: string): Promise<ProductoSat[]> => {
    if (!clave) return [];

    try {
      const result = await this.props.dispatcher.getProductosNombreClaveDuplicados(
        clave
      );

      return (result.productosSat || []).filter(
        producto =>
          !this.props.producto || producto.id !== this.props.producto.id
      );
    } catch (error) {
      console.error("Error buscando duplicados por clave:", error);
      return [];
    }
  };

  onSubmit = async (values: FormValue) => {
    try {
      this.setState({
        duplicadosNombre: [],
        duplicadosClave: []
      });

      const duplicadosNombre = await this.buscarDuplicadosPorNombre(
        values.name
      );
      const duplicadosClave = await this.buscarDuplicadosPorClave(
        values.claveProducto
      );

      if (duplicadosNombre.length > 0 || duplicadosClave.length > 0) {
        this.setState({
          duplicadosNombre,
          duplicadosClave
        });
        return;
      }

      const { producto, dispatcher, onClose, onProductoSaved } = this.props;

      const payload = {
        id: producto ? producto.id : undefined,
        name: values.name,
        claveProducto: values.claveProducto
      };

      const actionMethod = producto
        ? dispatcher.actualizarProductoSat
        : dispatcher.crearProductoSat;

      const result = await actionMethod(payload);

      const wasSuccessful = result && result.fulfilled;

      if (wasSuccessful) {
        successNotification(
          producto
            ? "Producto actualizado correctamente"
            : "Producto creado correctamente"
        );

        onClose();

        if (onProductoSaved) {
          onProductoSaved();
        }
      } else {
        errorNotification("Error al guardar el producto");
      }
    } catch (error) {
      console.error("Error completo:", error);
      errorNotification("Ocurrió un error inesperado");
    }
  };

  render() {
    const { isOpen, onClose, producto } = this.props;
    const { duplicadosNombre, duplicadosClave } = this.state;

    const initialValues: { [key in Inputs]: any } = {
      name: producto ? producto.nombre : "",
      claveProducto: producto ? producto.claveProducto : ""
    };

    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        size="lg"
        style={{
          maxWidth: "700px",
          margin: "1.75rem auto"
        }}
      >
        <ModalHeader toggle={onClose}>
          {producto ? "Editar Producto SAT" : "Crear Producto SAT"}
        </ModalHeader>

        {duplicadosNombre.length > 0 && (
          <div className="px-5 pt-3">
            <ProductosDuplicadosModal
              duplicados={duplicadosNombre}
              esNombre={true}
            />
          </div>
        )}

        {duplicadosClave.length > 0 && (
          <div className="px-5 pt-3">
            <ProductosDuplicadosModal
              duplicados={duplicadosClave}
              esNombre={false}
            />
          </div>
        )}

        <ModalBody className="px-5 pt-3 justify-content-center">
          <FormBuilder<Inputs, Buttons>
            config={formConfig}
            submit={this.onSubmit}
            init={initialValues}
            processing={this.props.saving}
          />
        </ModalBody>
      </Modal>
    );
  }
}
