import {
  GetAllActiveEstados,
  GetAlActiveMunicipiosByEstado,
  BuscarLocalidades
} from "../../reducers/codigoPostal";
import LocalidadDuplicada from "../../../types/codigoPostal";

export interface CodigoPostalStore
  extends GetAllActiveEstados,
    GetAlActiveMunicipiosByEstado,
    BuscarLocalidades {
  searching: boolean;
  saving: boolean;
  deleting: boolean;
  creating: boolean;
  updating: boolean;
  localidadesDuplicadas: LocalidadDuplicada[];
}

export const codigoPostaltore: (state) => CodigoPostalStore = state => {
  const codigoPostalState = state.codigoPostalReducer || {};

  return {
    estados: Array.isArray(codigoPostalState.estados)
      ? codigoPostalState.estados
      : [],
    municipios: Array.isArray(codigoPostalState.municipios)
      ? codigoPostalState.municipios
      : [],
    localidades: Array.isArray(codigoPostalState.localidades)
      ? codigoPostalState.localidades
      : [],
    searching: codigoPostalState.searching || false,
    saving: codigoPostalState.saving || false,
    deleting: codigoPostalState.deleting || false,
    creating: codigoPostalState.creating || false,
    updating: codigoPostalState.updating || false,
    localidadesDuplicadas: Array.isArray(
      codigoPostalState.localidadesDuplicadas
    )
      ? codigoPostalState.localidadesDuplicadas
      : []
  };
};
