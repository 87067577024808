import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import MexicanCurrency from "../common/currency";
import ReactTable from "react-table";
import s from "./styles.scss";
import { Container, Col, Button, Row, Label, Form } from "reactstrap";
import DatePicker from "../common/DatePicker";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../toolkit/baseInput";
import { withRouter } from "react-router-dom";
import IAction from "../../../types/IAction";
import EmployeeSelector from "../../../Selectors/components/EmployeeSelector/index";
import {
  TOTAL_SALES_REPORT_SALESMAN,
  TOTAL_SALES_REPORT_CLUB,
  TOTAL_SALES_REPORT_REGION,
  AMOUNT_SALES_REPORT_SALESMAN,
  AMOUNT_SALES_REPORT_CLUB,
  AMOUNT_SALES_REPORT_REGION
} from "./interfaces";

interface Membership {
  nombre: string;
  datosBasicosMembresiaId: string;
}

interface Props {
  getTotalSalesReport: (fields: object) => IAction;
  getAmountSalesReport: (fields: object) => IAction;
  memberships: Membership[];
  loadingTotalSales: boolean;
  reportTotalSales: TOTAL_SALES_REPORT_REGION[];
  loadingAmountSales: boolean;
  reportAmountSales: AMOUNT_SALES_REPORT_REGION[];
}

interface State {
  filters: object;
  msgLapsoFechas: boolean;
  responsable: {
    label: string;
    value: string;
  };
  onClickSearch: boolean;
  onClickSearchAmount: boolean;
  title: string;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class SalesSearch extends React.Component<Props, State> {
  state = {
    filters: {
      fechaInicio: null,
      fechaFin: null,
      membresiaId: null
    },
    responsable: null,
    onClickSearch: false,
    onClickSearchAmount: false,
    msgLapsoFechas: false,
    title: "Ventas"
  };

  totalRegionColumns = [
    {
      Header: () => <p className="font-weight-bold">Región</p>,
      id: 0,
      width: 180,
      accessor: "region",
      Cell: row => {
        return <p className={s.initial}>{row.original.region}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 120,
      accessor: "ventas",
      Cell: row => {
        return <p className={s.initial}>{row.original.ventas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 130,
      accessor: "corporativas",
      Cell: row => {
        return <p className={s.initial}>{row.original.corporativas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 120,
      accessor: "total",
      Cell: row => {
        return <p className={s.initial}>{row.original.total}</p>;
      }
    }
  ];

  totalClubColumns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 180,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 120,
      accessor: "ventas",
      Cell: row => {
        return <p className={s.initial}>{row.original.ventas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 130,
      accessor: "corporativas",
      Cell: row => {
        return <p className={s.initial}>{row.original.corporativas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 120,
      accessor: "total",
      Cell: row => {
        return <p className={s.initial}>{row.original.total}</p>;
      }
    }
  ];

  totalSalesmanColumns = [
    {
      Header: () => <p className="font-weight-bold">Vendedor</p>,
      id: 0,
      width: 250,
      accessor: "vendedorNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.vendedorNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 120,
      accessor: "ventas",
      Cell: row => {
        return <p className={s.initial}>{row.original.ventas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 130,
      accessor: "corporativas",
      Cell: row => {
        return <p className={s.initial}>{row.original.corporativas}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 120,
      accessor: "total",
      Cell: row => {
        return <p className={s.initial}>{row.original.total}</p>;
      }
    }
  ];

  amountRegionColumns = [
    {
      Header: () => <p className="font-weight-bold">Región</p>,
      id: 0,
      width: 160,
      accessor: "region",
      Cell: row => {
        return <p className={s.initial}>{row.original.region}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 160,
      accessor: "ventas",
      Cell: row => {
        if (row.original.region === "Total") {
          return <p className={s.initial}></p>;
        }
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.ventas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 160,
      accessor: "corporativas",
      Cell: row => {
        if (row.original.region === "Total") {
          return <p className={s.initial}></p>;
        }
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.corporativas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Subtotales</p>,
      id: 0,
      width: 160,
      accessor: "subtotal",
      Cell: row => {
        if (row.original.region === "Total") {
          return <p className={s.initial}></p>;
        }
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.subtotal} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 160,
      accessor: "total",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.total} />
          </p>
        );
      }
    }
  ];

  amountClubColumns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 160,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 160,
      accessor: "ventas",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.ventas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 160,
      accessor: "corporativas",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.corporativas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Subtotales</p>,
      id: 0,
      width: 160,
      accessor: "subtotal",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.subtotal} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 160,
      accessor: "total",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.total} />
          </p>
        );
      }
    }
  ];

  amountSalesmanColumns = [
    {
      Header: () => <p className="font-weight-bold">Vendedor</p>,
      id: 0,
      width: 250,
      accessor: "vendedorNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.vendedorNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">{this.state.title}</p>,
      id: 0,
      width: 160,
      accessor: "ventas",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.ventas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Corporativas</p>,
      id: 0,
      width: 160,
      accessor: "corporativas",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.corporativas} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Subtotales</p>,
      id: 0,
      width: 160,
      accessor: "subtotal",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.subtotal} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Totales</p>,
      id: 0,
      width: 160,
      accessor: "total",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.total} />
          </p>
        );
      }
    }
  ];

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeSeller = responsable => {
    this.setState({ responsable: responsable || null });
  };

  validarFechas = () => {
    let fechaInicio = new Date(this.state.filters.fechaInicio);
    let fechaFin = new Date(this.state.filters.fechaFin);

    const lapsoFechas = Math.floor(
      (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24)
    );

    return lapsoFechas <= 30 ? true : false;
  };

  search = () => {
    this.setState({
      onClickSearch: true,
      onClickSearchAmount: true
    });
    let lapsoValido = this.validarFechas();
    if (lapsoValido) {
      const tipoVenta = this.state.filters.membresiaId;
      this.setState({
        msgLapsoFechas: false,
        title: tipoVenta
          ? tipoVenta === "M"
            ? "Membresías"
            : "Reactivaciones"
          : "Ventas"
      });
      this.props.getTotalSalesReport({
        fechaInicio: this.state.filters.fechaInicio,
        fechaFin: this.state.filters.fechaFin,
        membresia: this.state.filters.membresiaId,
        vendedorId: this.state.responsable
      });
      this.props.getAmountSalesReport({
        fechaInicio: this.state.filters.fechaInicio,
        fechaFin: this.state.filters.fechaFin,
        membresia: this.state.filters.membresiaId,
        vendedorId: this.state.responsable
      });
    } else {
      this.setState({ msgLapsoFechas: true });
    }
  };

  // Métodos para tabla de unidades
  obtenerSumaVentasUnidades = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.ventas || 0), 0);
  };

  obtenerSumaCorporativasUnidades = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.corporativas || 0), 0);
  };

  obtenerSumaTotalesUnidades = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.total || 0), 0);
  };

  // Métodos para tabla de ingresos
  obtenerSumaVentasIngresos = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.ventas || 0), 0);
  };

  obtenerSumaCorporativasIngresos = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.corporativas || 0), 0);
  };

  obtenerSumaSubtotalesIngresos = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.subtotal || 0), 0);
  };

  obtenerSumaTotalesIngresos = data => {
    if (!data || data.length === 0) return 0;
    return data.reduce((sum, item) => sum + (item.total || 0), 0);
  };

  render() {
    const mostrarDatosUnidades =
      this.state.onClickSearch && this.props.reportTotalSales !== null;
    const mostrarDatosIngresos =
      this.state.onClickSearchAmount && this.props.reportAmountSales !== null;

    const datosUnidades = mostrarDatosUnidades
      ? this.props.reportTotalSales
      : [];

    const sumaVentasUnidades = this.obtenerSumaVentasUnidades(datosUnidades);
    const sumaCorporativasUnidades = this.obtenerSumaCorporativasUnidades(
      datosUnidades
    );
    const sumaTotalesUnidades = this.obtenerSumaTotalesUnidades(datosUnidades);

    const datosUnidadesConTotal = [
      ...datosUnidades,
      ...(mostrarDatosUnidades
        ? [
            {
              region: "Total",
              ventas: "",
              corporativas: "",
              total: sumaTotalesUnidades
            }
          ]
        : [])
    ];

    const datosIngresos = mostrarDatosIngresos
      ? this.props.reportAmountSales
      : [];

    const sumaVentasIngresos = this.obtenerSumaVentasIngresos(datosIngresos);
    const sumaCorporativasIngresos = this.obtenerSumaCorporativasIngresos(
      datosIngresos
    );
    const sumaSubtotalesIngresos = this.obtenerSumaSubtotalesIngresos(
      datosIngresos
    );
    const sumaTotalesIngresos = this.obtenerSumaTotalesIngresos(datosIngresos);

    const datosIngresosConTotal = [
      ...datosIngresos,
      ...(mostrarDatosIngresos
        ? [
            {
              region: "Total",
              ventas: "",
              corporativas: "",
              subtotal: "",
              total: sumaTotalesIngresos
            }
          ]
        : [])
    ];

    return (
      <Container className="p-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col xs={12} md={3} lg={2}>
              <DatePicker
                label="Desde"
                name={START_DATE_FIELDNAME}
                id={START_DATE_FIELDNAME}
                selected={this.state.filters[START_DATE_FIELDNAME]}
                onChange={this.onChangeDate(START_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={3} lg={2}>
              <DatePicker
                label="Hasta"
                name={END_DATE_FIELDNAME}
                id={END_DATE_FIELDNAME}
                selected={this.state.filters[END_DATE_FIELDNAME]}
                onChange={this.onChangeDate(END_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <EmployeeSelector
                onChange={(_, value) => this.onChangeSeller(value)}
                label="Responsable de venta"
              />
            </Col>
            <Col xs={12} md={3} lg={2}>
              <BaseInput
                label="Tipo de venta"
                name="membresia"
                id="membresia"
                type="select"
                placeholder="Todas"
                value={this.state.filters.membresiaId}
                onChange={this.onChangeFilter("membresiaId")}
                size={"sm"}
                options={[
                  { value: "", label: "Todas" },
                  { value: "M", label: "Membresías" },
                  { value: "R", label: "Reactivaciones" }
                ]}
              />
            </Col>
            <Col xs={12} md={3} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={
                  this.state.filters.fechaInicio === null ||
                  this.state.filters.fechaFin === null ||
                  this.props.loadingTotalSales ||
                  this.props.loadingAmountSales
                }
              >
                {this.props.loadingTotalSales || this.props.loadingAmountSales
                  ? "Espere..."
                  : "Buscar"}
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.msgLapsoFechas ? (
          <Row>
            <Col className={s.msgError}>
              El lapso de "Fecha inicio" y "Fecha fin" debe ser menor o igual a
              30 días
            </Col>
          </Row>
        ) : (
          ""
        )}

        <Row>
          <Col xs={12} md={12} lg={6}>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Unidad</p>
              </div>
            </div>
            <ReactTable
              data={datosUnidadesConTotal}
              columns={this.totalRegionColumns}
              className={`${s.customTable} mt-5 -striped -highlight`}
              sortable={false}
              resizable={false}
              pageSize={datosUnidadesConTotal.length || 2}
              showPagination={false}
              loading={
                this.state.onClickSearch && this.props.reportTotalSales === null
              }
              SubComponent={row => {
                if (row.original.region === "Total") return null;
                return (
                  <div className={s.subtabla}>
                    <ReactTable
                      data={row.original.clubes || []}
                      columns={this.totalClubColumns}
                      defaultPageSize={(row.original.clubes || []).length || 2}
                      showPagination={false}
                      SubComponent={row => {
                        return (
                          <div className={s.subtabla}>
                            <ReactTable
                              data={row.original.vendedores || []}
                              columns={this.totalSalesmanColumns}
                              defaultPageSize={
                                (row.original.vendedores || []).length || 2
                              }
                              showPagination={false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                );
              }}
              getTrProps={(_state, rowInfo) => {
                if (rowInfo && rowInfo.original.region === "Total") {
                  return {
                    style: {
                      fontWeight: "bold",
                      color: "black",
                      fontSize: "1.0rem"
                      /* backgroundColor: "#ce072c", */
                    }
                  };
                }
                return {};
              }}
            />
          </Col>

          <Col xs={12} md={12} lg={6}>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Ingreso</p>
              </div>
            </div>
            <ReactTable
              data={datosIngresosConTotal}
              columns={this.amountRegionColumns}
              className={`${s.customTable} mt-5 -striped -highlight`}
              sortable={false}
              resizable={false}
              showPagination={false}
              pageSize={datosIngresosConTotal.length || 2}
              loading={
                this.state.onClickSearchAmount &&
                this.props.reportAmountSales === null
              }
              SubComponent={row => {
                if (row.original.region === "Total") return null;
                return (
                  <div className={s.subtabla}>
                    <ReactTable
                      data={row.original.clubes || []}
                      columns={this.amountClubColumns}
                      defaultPageSize={(row.original.clubes || []).length || 2}
                      showPagination={false}
                      SubComponent={row => {
                        return (
                          <div className={s.subtabla}>
                            <ReactTable
                              data={row.original.vendedores || []}
                              columns={this.amountSalesmanColumns}
                              defaultPageSize={
                                (row.original.vendedores || []).length || 2
                              }
                              showPagination={false}
                            />
                          </div>
                        );
                      }}
                    />
                  </div>
                );
              }}
              getTrProps={(_state, rowInfo) => {
                if (rowInfo && rowInfo.original.region === "Total") {
                  return {
                    style: {
                      fontWeight: "bold",
                      fontSize: "1.0rem",
                      color: "black"
                      /* backgroundColor: "#ce072c", */
                    }
                  };
                }
                return {};
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SalesSearch));
