import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import Estado from "../../types/codigoPostal";
import Municipio from "../../types/codigoPostal";
import Localidad from "../../types/codigoPostal";
import {
  errorNotification,
  successNotification
} from "../../utils/notifications";
import LocalidadDuplicada from "../../types/codigoPostal";

export interface GetAllActiveEstados {
  estados: Estado[];
}

export interface GetAlActiveMunicipiosByEstado {
  municipios: Municipio[];
}

export interface BuscarLocalidades {
  localidades: Localidad[];
}

interface State
  extends GetAllActiveEstados,
    GetAlActiveMunicipiosByEstado,
    BuscarLocalidades {
  searching: boolean;
  saving: boolean;
  deleting: boolean;
  creating: boolean;
  updating: boolean;
  localidadesDuplicadas: LocalidadDuplicada[];
}

const initialState: State = {
  estados: null,
  municipios: null,
  localidades: null,
  searching: false,
  saving: false,
  deleting: false,
  creating: false,
  updating: false,
  localidadesDuplicadas: []
};

export default function codigoPostalReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_PENDING`: {
      /* infoNotification("Obteniendo los estados"); */
      return {
        ...state,
        searching: true,
        estados: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_REJECTED`: {
      errorNotification("Error al obtener los estados");
      return {
        ...state,
        searching: false,
        estados: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_ESTADOS}_FULFILLED`: {
      successNotification("Estados cargados correctamente");
      return {
        ...state,
        searching: false,
        estados: action.payload.data
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_PENDING`: {
      /* infoNotification("Obteniendo los municipios"); */
      return {
        ...state,
        searching: true,
        municipios: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_REJECTED`: {
      errorNotification("Error al obtener los municipios");
      return {
        ...state,
        searching: false,
        municipios: null
      };
    }
    case `${actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO}_FULFILLED`: {
      successNotification("Municipios cargados correctamente");
      return {
        ...state,
        searching: false,
        municipios: action.payload.data
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_PENDING`: {
      /* infoNotification("Obteniendo las localidades duplicadas"); */
      return {
        ...state,
        searching: true,
        localidadesDuplicadas: []
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_REJECTED`: {
      errorNotification("Error al obtener las localidades duplicadas");
      return {
        ...state,
        searching: false,
        localidadesDuplicadas: []
      };
    }
    case `${actionTypes.GET_LOCALIDADES_DUPLICADAS}_FULFILLED`: {
      successNotification("Localidades duplicadas cargadas correctamente");
      return {
        ...state,
        searching: false,
        localidadesDuplicadas: action.payload.data
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_PENDING`: {
      /* infoNotification("Creando la localidad"); */
      return {
        ...state,
        creating: true,
        localidades: null
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al crear la localidad");
      return {
        ...state,
        creating: false,
        localidades: null
      };
    }
    case `${actionTypes.POST_LOCALIDAD}_FULFILLED`: {
      successNotification("Localidad creada correctamente");
      return {
        ...state,
        creating: false,
        localidades: action.payload.data
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_PENDING`: {
      /* infoNotification("Obteniendo las localidades con los filtros"); */
      return {
        ...state,
        searching: true,
        localidades: null
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_REJECTED`: {
      errorNotification("Error al obtener las localidades con los filtros");
      return {
        ...state,
        searching: false,
        localidades: null
      };
    }
    case `${actionTypes.GET_LOCALIDADES_CUSTOM}_FULFILLED`: {
      successNotification("Localidades cargadas correctamente con los filtros");
      return {
        ...state,
        searching: false,
        localidades: action.payload.data
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_PENDING`: {
      /* infoNotification("Actualizando la localidad"); */
      return {
        ...state,
        updating: true,
        localidades: null
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al actualizar la localidad");
      return {
        ...state,
        updating: false,
        localidades: null
      };
    }
    case `${actionTypes.PUT_LOCALIDAD}_FULFILLED`: {
      successNotification("Localidad actualizada correctamente");
      return {
        ...state,
        updating: false
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_PENDING`: {
      /* infoNotification("Eliminando la localidad"); */
      return {
        ...state,
        deleting: true
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_REJECTED`: {
      errorNotification("Error al eliminar la localidad");
      return {
        ...state,
        deleting: false
      };
    }
    case `${actionTypes.DELETE_LOCALIDAD}_FULFILLED`: {
      const localidadId = action.meta.id;
      successNotification("Localidad eliminada correctamente");
      return {
        ...state,
        localidades: state.localidades
          ? state.localidades.filter(loc => loc.localidadId !== localidadId)
          : [],
        deleting: false
      };
    }
    case `${actionTypes.POST_CODIGO_POSTAL}_PENDING`: {
      /* infoNotification("Creando el codigo postal"); */
      return {
        ...state,
        creating: true
      };
    }
    case `${actionTypes.POST_CODIGO_POSTAL}_REJECTED`: {
      const errorPorDefecto = "Error al crear el código postal";
      console.error(errorPorDefecto, action.payload);

      const errorBackend = getErrorBackend(action, errorPorDefecto);
      errorNotification(errorBackend);

      return {
        ...state,
        creating: false
      };
    }
    case `${actionTypes.POST_CODIGO_POSTAL}_FULFILLED`: {
      successNotification("Codigo postal creado correctamente");
      return {
        ...state,
        creating: false
      };
    }
    default: {
      return state;
    }
  }
}

export const getErrorBackend = (
  action,
  defaultMessage = "Ha ocurrido un error"
) => {
  if (!action || !action.payload) {
    return defaultMessage;
  }

  const responseData =
    (action.payload.response && action.payload.response.data) ||
    (action.payload.config &&
      action.payload.config.response &&
      action.payload.config.response.data) ||
    null;

  if (!responseData) {
    return defaultMessage;
  }

  if (typeof responseData === "string") {
    return responseData;
  } else if (responseData.message) {
    return responseData.message;
  } else {
    return JSON.stringify(responseData);
  }
};
