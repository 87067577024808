import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

export type Inputs = "name" | "claveProducto";
export type Buttons = "guardar";
export type FormValue = { [key in Inputs]: any };

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "name",
      label: "Nombre del Producto",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 255
      },
      bts: {
        mCol: 6,
        mOff: 0
      }
    },
    {
      name: "claveProducto",
      label: "Clave del Producto",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 10
      },
      bts: {
        mCol: 6,
        mOff: 0
      }
    }
  ],
  buttons: [
    {
      name: "guardar",
      label: "Guardar Producto",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mCol: 12
      }
    }
  ],
  validations: {
    name: {
      required: true
    },
    claveProducto: {
      required: true
    }
  },
  rows: [["name", "claveProducto"], ["guardar"]]
};
