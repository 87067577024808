import React, { useState } from "react";
import ReactTable from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import ProductoSat from "../../../types/ProductoSat";
import { useEffect } from "react";

export interface Props {
  page: ProductoSat[];
  onAddClick: () => void;
  onEditClick: (producto: ProductoSat) => void;
  onDeleteClick: (id: number) => void;
}

export const ProductosSatTable = React.memo(
  ({ page, onAddClick, onEditClick, onDeleteClick }: Props) => {
    const [columns] = useState(
      columnsFormatter(onAddClick, onEditClick, onDeleteClick)
    );

    const pageData = Array.isArray(page) ? page : [];
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(100);

    useEffect(() => {
      setPageNumber(0);
    }, [page]);

    const onPageChange = (pageIndex: number) => {
      setPageNumber(pageIndex);
    };

    const onPageSizeChange = (newPageSize: number) => {
      setPageSize(newPageSize);
      setPageNumber(0);
    };

    const paginatedData = pageData.slice(
      pageNumber * pageSize,
      (pageNumber + 1) * pageSize
    );

    return (
      <>
        <div className="row mt-2">
          <div style={{ width: "100%" }}>
            <ReactTable
              manual
              data={paginatedData}
              columns={columns}
              loading={false}
              defaultPageSize={100}
              pageSize={pageSize}
              className="-striped -highlight"
              page={pageNumber}
              pages={Math.ceil(pageData.length / pageSize)}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              showPagination={pageData.length > pageSize}
              showPageSizeOptions={true}
              pageSizeOptions={[5, 10, 20, 50, 100]}
              getTrProps={() => ({ style: { height: "30px" } })}
              sortable={false}
            />
          </div>
        </div>
      </>
    );
  }
);

function columnsFormatter(
  onAddClick: () => void,
  onEditClick: (producto: ProductoSat) => void,
  onDeleteClick: (id: number) => void
) {
  return [
    {
      Header: "ID",
      id: "idProducto",
      className: s.tdClear,
      accessor: simpleAccessor("id")
    },
    {
      Header: "Nombre",
      id: "nombre",
      className: s.tdClear,
      accessor: simpleAccessor("nombre")
    },
    {
      Header: "Clave Producto",
      id: "claveProducto",
      className: s.tdClear,
      accessor: simpleAccessor("claveProducto")
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <span id="productoAddId" className={s.btn} onClick={onAddClick}>
            <FontAwesomeIcon icon={faPlus} />
            <UncontrolledTooltip placement="left" target="productoAddId">
              Crear Producto
            </UncontrolledTooltip>
          </span>
        </div>
      ),
      id: "acciones",
      accessor: (row: ProductoSat) => (
        <div className={`text-center`}>
          <span
            id="productoEditId"
            className={s.btn}
            onClick={() => onEditClick(row)}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
            <UncontrolledTooltip placement="left" target="productoEditId">
              Editar Producto
            </UncontrolledTooltip>
          </span>
          <span
            id="productoDeleteId"
            className={s.btn}
            onClick={() => onDeleteClick(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
            <UncontrolledTooltip placement="right" target="productoDeleteId">
              Borrar Producto
            </UncontrolledTooltip>
          </span>
        </div>
      )
    }
  ];
}

function simpleAccessor(key: keyof ProductoSat) {
  return (row: ProductoSat) => {
    return <div className={s.field}>{row[key]}&nbsp;</div>;
  };
}
