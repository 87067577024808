import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import {
  ProductosSatDispatcher,
  productosSatDispatcher
} from "./productosSat.dispatcher";
import { ProductosSatStore, productosSatStore } from "./productosSat.store";
import ProductosSatComponent from "../../components/productosSat";

interface Props extends ProductosSatStore, ProductosSatDispatcher {}
interface State {}

@ReduxConnect(productosSatStore, productosSatDispatcher)
export default class ProductosSatContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Productos SAT" />
          <ProductosSatComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
