import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";

const LOCALIDADES_BASE_URL = "/api/address/localidades";
const CODIGO_POSTAL_BASE_URL = "/api/address/codigos-postales";

export const getAllActiveEstados = (): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.GET_ALL_ACTIVE_ESTADOS,
    urlComplement: `/estados`,
    method: "get"
  });
};

export const getAllActiveMunicipiosByEstado = (id: number): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.GET_ALL_ACTIVE_MUNICIPIOS_BY_ESTADO,
    urlComplement: `/municipios/${id}`,
    method: "get"
  });
};

export const getLocalidadesDuplicadas = (
  municipioId: number,
  codigoId: number,
  localidad: string
): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.GET_LOCALIDADES_DUPLICADAS,
    urlComplement: `/duplicadas`,
    method: "get",
    params: {
      municipioId: municipioId,
      codigoId: codigoId,
      localidad: localidad
    }
  });
};

export const guardarLocalidad = (body: any): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.POST_LOCALIDAD,
    urlComplement: `/`,
    method: "post",
    body: body
  });
};

export const buscarLocalidades = (
  municipioId?: number,
  codigoId?: string,
  nombre?: string
): IAction => {
  const params: any = {};

  if (
    municipioId !== undefined &&
    municipioId !== null &&
    !isNaN(municipioId)
  ) {
    params.municipioId = municipioId;
  }

  if (codigoId !== undefined && codigoId !== null && codigoId !== "") {
    params.codigoId = codigoId;
  }

  if (nombre !== undefined && nombre !== null && nombre !== "") {
    params.nombre = nombre;
  }

  return localidadesRestAction({
    actionType: actionTypes.GET_LOCALIDADES_CUSTOM,
    urlComplement: `/`,
    method: "get",
    params
  });
};

export const actualizarLocalidad = (
  localidadId: number,
  body: any
): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.PUT_LOCALIDAD,
    urlComplement: `/${localidadId}`,
    method: "put",
    body: body
  });
};

export const eliminarLocalidad = (localidadId: number): IAction => {
  return localidadesRestAction({
    actionType: actionTypes.DELETE_LOCALIDAD,
    urlComplement: `/${localidadId}`,
    method: "delete"
  });
};

export const guardarCodigoPostal = (body: any): IAction => {
  return codigoPostalRestAction({
    actionType: actionTypes.POST_CODIGO_POSTAL,
    urlComplement: `/`,
    method: "post",
    body: body
  });
};

const localidadesRestAction = restActionFactory(
  `${constants.BASE_URL}${LOCALIDADES_BASE_URL}`
);

const codigoPostalRestAction = restActionFactory(
  `${constants.BASE_URL}${CODIGO_POSTAL_BASE_URL}`
);
