import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container } from "reactstrap";
import landingPage from "./landingPage.scss";

interface ArchivoDTO {
  nombre: string;
  enlace: string;
  orden: number;
  activo: boolean;
}

interface SubmoduloDTO {
  nombre: string;
  orden: number;
  activo: boolean;
  archivos: ArchivoDTO[];
}

interface ModuloDTO {
  nombre: string;
  orden: number;
  activo: boolean;
  submodulos: SubmoduloDTO[];
  archivos: ArchivoDTO[];
}

interface DocumentacionResponseDTO {
  modulos: ModuloDTO[];
}

interface Props extends RouteComponentProps {
  getDocumentation: () => any;
  documentation: DocumentacionResponseDTO;
  loading: boolean;
  error: string | null;
}

class LandingPage extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.getDocumentation();
  }

  renderArchivos = (archivos: ArchivoDTO[]) => {
    const sortedArchivos = [...archivos].sort((a, b) => a.orden - b.orden);

    return sortedArchivos.map(
      (archivo, index) =>
        archivo.activo && (
          <div key={index} className="archivo-item">
            <div style={{ fontWeight: "bold", marginTop: "8px" }}>
              {archivo.nombre}
            </div>
            <div style={{ marginBottom: "8px" }}>
              <a
                href={archivo.enlace}
                target="_blank"
                rel="noopener noreferrer"
              >
                {archivo.enlace.length > 64
                  ? archivo.enlace.substring(0, 61) + "..."
                  : archivo.enlace}
              </a>
            </div>
          </div>
        )
    );
  };

  renderSubmodulos = (submodulos: SubmoduloDTO[]) => {
    const sortedSubmodulos = [...submodulos].sort((a, b) => a.orden - b.orden);

    return sortedSubmodulos.map(
      (submodulo, index) =>
        submodulo.activo && (
          <div key={index} className="submodulo-container">
            <h5>{submodulo.nombre}</h5>
            <div className="archivos-container">
              {this.renderArchivos(submodulo.archivos)}
            </div>
          </div>
        )
    );
  };

  renderModulos = () => {
    const { documentation } = this.props;
    if (!documentation) return null;

    const sortedModulos = [...documentation.modulos]
      .filter(modulo => modulo.activo)
      .sort((a, b) => a.orden - b.orden);

    return (
      <div className="modulos-grid">
        {sortedModulos.map((modulo, index) => (
          <div key={index} className="modulo-container">
            <h4>{modulo.nombre}:</h4>
            {modulo.submodulos.length > 0 ? (
              this.renderSubmodulos(modulo.submodulos)
            ) : (
              <div className="archivos-container">
                {this.renderArchivos(modulo.archivos)}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, error } = this.props;
    return (
      <div className={landingPage.root}>
        <Container>
          <div>&nbsp;</div>
          <div className={landingPage.content}>
            {loading ? (
              <div>Cargando documentación...</div>
            ) : error ? (
              <div>Error: {error}</div>
            ) : (
              this.renderModulos()
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(landingPage)(LandingPage));
