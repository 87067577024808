exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span.i1HFF{cursor:pointer;font-size:16px;margin:0 8px}._1C-j3{color:#fff;background-color:#ce072c;padding:5px 8px;font-weight:700;border-radius:8px}._2Jb26,._2rClh{color:#fff;background-color:#ce072c;padding:3px 8px;height:20px;border-radius:8px;font-size:13.6px;font-size:.85rem}._2Jb26{font-weight:700}", ""]);

// exports
exports.locals = {
	"btn": "i1HFF",
	"primaryTitle": "_1C-j3",
	"secondaryTitle": "_2rClh",
	"grayTitle": "_2Jb26"
};