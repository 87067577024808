import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import LandingPage from "../../components/landingPage";
import * as landingPageActions from "../../actions/landingPage";

interface Props {
  getDocumentation: () => any;
  documentation: any;
  loading: boolean;
  error: any;
}

class LandingPageContainer extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Inicio" />
        <Layout>
          <LandingPage {...this.props} />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = (state: { landingPage: any }) => ({
  documentation: state.landingPage.documentation,
  loading: state.landingPage.loading,
  error: state.landingPage.error
});

const mapDispatchToProps = dispatch => ({
  getDocumentation: () => dispatch(landingPageActions.getDocumentation())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageContainer);
