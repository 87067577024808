import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { readMessageBack } from "../../utils/readMessageBack";
import ProspectoCrmParams from "../../types/ProspectoCrmParams";
import ProspectoCrm from "../../types/ProspectoCrm";
import ProspectoRepetido from "../../types/ProspectoRepetido";

export interface GetProspectoCrmParams {
  prospectoCrmParams: ProspectoCrmParams;
  hasProspectoCrmParams: boolean;
  gettingProspectoCrmParams: boolean;
  gettingProspectoCrmParamsError: string;
}

export interface GetProspectoCrmRenapo {
  prospectoCrmRenapo: ProspectoCrm;
  gettingProspectoCrmRenapo: boolean;
  gettingProspectoCrmRenapoError: string;
}

export interface PostProspectoCrm {
  prospectoCrm: ProspectoCrm;
  postingProspectoCrm: boolean;
  postingProspectoCrmError: string;
}

export interface GetProspectosCrmRepetidosCurp {
  prospectosCrmRepetidosCurp: ProspectoRepetido[];
  gettingProspectosCrmRepetidosCurp: boolean;
  gettingProspectosCrmRepetidosCurpError: string;
}

export interface GetProspectosCrmRepetidosCorreo {
  prospectosCrmRepetidosCorreo: ProspectoRepetido[];
  gettingProspectosCrmRepetidosCorreo: boolean;
  gettingProspectosCrmRepetidosCorreoError: string;
}

interface State
  extends GetProspectoCrmParams,
    GetProspectoCrmRenapo,
    PostProspectoCrm,
    GetProspectosCrmRepetidosCurp,
    GetProspectosCrmRepetidosCorreo {}

const initialState: State = {
  prospectoCrmParams: {} as ProspectoCrmParams,
  hasProspectoCrmParams: false,
  gettingProspectoCrmParams: false,
  gettingProspectoCrmParamsError: null,

  prospectoCrmRenapo: null,
  gettingProspectoCrmRenapo: false,
  gettingProspectoCrmRenapoError: null,

  prospectoCrm: null,
  postingProspectoCrm: false,
  postingProspectoCrmError: null,

  prospectosCrmRepetidosCurp: null,
  gettingProspectosCrmRepetidosCurp: false,
  gettingProspectosCrmRepetidosCurpError: null,

  prospectosCrmRepetidosCorreo: null,
  gettingProspectosCrmRepetidosCorreo: false,
  gettingProspectosCrmRepetidosCorreoError: null
};

export default function prospectosReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    // CLEAR_PROSPECTO_CRM
    case actionTypes.CLEAR_PROSPECTO_CRM: {
      return {
        prospectoCrmRenapo: null,
        gettingProspectoCrmRenapo: false,
        gettingProspectoCrmRenapoError: null,

        prospectoCrm: null,
        postingProspectoCrm: false,
        postingProspectoCrmError: null,

        prospectosCrmRepetidosCurp: null,
        gettingProspectosCrmRepetidosCurp: false,
        gettingProspectosCrmRepetidosCurpError: null,

        prospectosCrmRepetidosCorreo: null,
        gettingProspectosCrmRepetidosCorreo: false,
        gettingProspectosCrmRepetidosCorreoError: null
      };
    }

    // GET_PROSPECTO_CRM_PARAMS
    case `${actionTypes.GET_PROSPECTO_CRM_PARAMS}_PENDING`: {
      return {
        prospectoCrmParams: {} as ProspectoCrmParams,
        hasProspectoCrmParams: false,
        gettingProspectoCrmParams: true,
        gettingProspectoCrmParamsError: null
      };
    }
    case `${actionTypes.GET_PROSPECTO_CRM_PARAMS}_REJECTED`: {
      return {
        prospectoCrmParams: {} as ProspectoCrmParams,
        hasProspectoCrmParams: false,
        gettingProspectoCrmParams: false,
        gettingProspectoCrmParamsError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_PROSPECTO_CRM_PARAMS}_FULFILLED`: {
      return {
        prospectoCrmParams: action.payload.data,
        hasProspectoCrmParams: true,
        gettingProspectoCrmParams: false,
        gettingProspectoCrmParamsError: null
      };
    }

    // GET_PROSPECTO_CRM_RENAPO
    case `${actionTypes.GET_PROSPECTO_CRM_RENAPO}_PENDING`: {
      return {
        prospectoCrmRenapo: null,
        gettingProspectoCrmRenapo: true,
        gettingProspectoCrmRenapoError: null
      };
    }
    case `${actionTypes.GET_PROSPECTO_CRM_RENAPO}_REJECTED`: {
      return {
        prospectoCrmRenapo: null,
        gettingProspectoCrmRenapo: false,
        gettingProspectoCrmRenapoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_PROSPECTO_CRM_RENAPO}_FULFILLED`: {
      return {
        prospectoCrmRenapo: action.payload.data,
        gettingProspectoCrmRenapo: false,
        gettingProspectoCrmRenapoError: null
      };
    }

    // POST_PROSPECTO_CRM
    case `${actionTypes.POST_PROSPECTO_CRM}_PENDING`: {
      return {
        prospectoCrm: null,
        postingProspectoCrm: true,
        postingProspectoCrmError: null
      };
    }
    case `${actionTypes.POST_PROSPECTO_CRM}_REJECTED`: {
      return {
        prospectoCrm: null,
        postingProspectoCrm: false,
        postingProspectoCrmError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_PROSPECTO_CRM}_FULFILLED`: {
      return {
        prospectoCrm: action.payload.data,
        postingProspectoCrm: false,
        postingProspectoCrmError: null
      };
    }

    // GET_PROSPECTOS_CRM_REPETIDOS_CURP
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CURP}_PENDING`: {
      return {
        prospectosCrmRepetidosCurp: null,
        gettingProspectosCrmRepetidosCurp: true,
        gettingProspectosCrmRepetidosCurpError: null
      };
    }
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CURP}_REJECTED`: {
      return {
        prospectosCrmRepetidosCurp: null,
        gettingProspectosCrmRepetidosCurp: false,
        gettingProspectosCrmRepetidosCurpError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CURP}_FULFILLED`: {
      return {
        prospectosCrmRepetidosCurp: action.payload.data,
        gettingProspectosCrmRepetidosCurp: false,
        gettingProspectosCrmRepetidosCurpError: null
      };
    }

    // GET_PROSPECTOS_CRM_REPETIDOS_CORREO
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CORREO}_PENDING`: {
      return {
        prospectosCrmRepetidosCorreo: null,
        gettingProspectosCrmRepetidosCorreo: true,
        gettingProspectosCrmRepetidosCorreoError: null
      };
    }
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CORREO}_REJECTED`: {
      return {
        prospectosCrmRepetidosCorreo: null,
        gettingProspectosCrmRepetidosCorreo: false,
        gettingProspectosCrmRepetidosCorreoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_PROSPECTOS_CRM_REPETIDOS_CORREO}_FULFILLED`: {
      return {
        prospectosCrmRepetidosCorreo: action.payload.data,
        gettingProspectosCrmRepetidosCorreo: false,
        gettingProspectosCrmRepetidosCorreoError: null
      };
    }

    default: {
      return state;
    }
  }
}
