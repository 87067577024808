import {
  CrearProductoSat,
  ActualizarProductoSat,
  GetProductosSat
} from "../../reducers/productosSat";

export interface ProductosSatStore
  extends GetProductosSat,
    CrearProductoSat,
    ActualizarProductoSat {
  searching: boolean;
  saving: boolean;
}

export const productosSatStore: (state) => ProductosSatStore = state => {
  const productosSatState = state.productosSatReducer || {};

  return {
    productosSat: Array.isArray(productosSatState.productosSat)
      ? productosSatState.productosSat
      : [],
    id: productosSatState.id || null,
    name: productosSatState.name || null,
    claveProducto: productosSatState.claveProducto || null,
    searching: productosSatState.searching || false,
    saving: productosSatState.saving || false
  };
};
