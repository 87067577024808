import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { ProductosSatDispatcher } from "../../containers/productosSat/productosSat.dispatcher";
import { ProductosSatStore } from "../../containers/productosSat/productosSat.store";
import s from "./styles.scss";
import { Container, Button } from "reactstrap";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import {
  Inputs,
  Buttons,
  formularioBusquedaProductosSat
} from "./ProductosSat.form";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { ProductosSatTable } from "./ProductosSat.table";
import ProductoSat from "../../../types/ProductoSat";
import ProductoSatModal from "./ProductoSatModal.component";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import FileDownload from "js-file-download";
import constants from "../../../utils/constants";

interface Props
  extends ProductosSatStore,
    ProductosSatDispatcher,
    RouterProps<any> {}

interface State {
  confirmPopupOpen: boolean;
  confirmPopupId: number | null;
  modalOpen: boolean;
  editingProducto: ProductoSat | null;
  filtroActual: {
    idProducto: number | null;
    nombre: string | null;
    clave: string | null;
  };
}
const PRODUCTOS_SAT_BASE_URL = "/api/catalogs/productos-sat";

@Router()
@Styled(s)
export default class ProductosSatComponent extends Component<Props, State> {
  state = {
    confirmPopupOpen: false,
    confirmPopupId: null,
    modalOpen: false,
    editingProducto: null,
    filtroActual: {
      idProducto: null,
      nombre: null,
      clave: null
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    await this.props.getProductosSatActivos();
  };

  submit = (values: any) => {
    const { idProducto, nombre, clave } = values;

    const idParam = idProducto || null;
    const nombreParam = nombre || null;
    const claveParam = clave || null;

    this.setState({
      filtroActual: {
        idProducto: idParam,
        nombre: nombreParam,
        clave: claveParam
      }
    });

    let mensaje = "";
    if (idParam) mensaje += ` con ID ${idParam}`;
    if (nombreParam) mensaje += ` con nombre "${nombreParam}"`;
    if (claveParam) mensaje += ` con clave "${claveParam}"`;

    const result = this.props.getProductosSatActivosCustom(
      idParam,
      nombreParam,
      claveParam
    );

    result
      .then(response => {
        if (response.productosSat && response.productosSat.length === 0) {
          errorNotification(`No se encontraron productos${mensaje}`);
        } else {
          successNotification(`Productos encontrados${mensaje}`);
        }
      })
      .catch(error => {
        console.error("Error en la búsqueda:", error);
        errorNotification("Ocurrió un error al buscar productos");
      });
  };

  handleExport = () => {
    try {
      if (!this.props.productosSat || this.props.productosSat.length === 0) {
        errorNotification("No hay datos para exportar");
        return;
      }

      const headers = ["ID", "Nombre", "Clave Producto"];
      const csvRows = [];

      csvRows.push(headers.join(","));

      this.props.productosSat.forEach(producto => {
        const values = [
          producto.id || "",
          producto.nombre || "",
          producto.claveProducto || ""
        ].map(value => {
          const stringValue = String(value);
          if (
            stringValue.includes(",") ||
            stringValue.includes('"') ||
            stringValue.includes("\n")
          ) {
            return `"${stringValue.replace(/"/g, '""')}"`;
          }
          return stringValue;
        });

        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const now = new Date();
      const dateStr = `${now.getFullYear()}-${String(
        now.getMonth() + 1
      ).padStart(2, "0")}-${String(now.getDate()).padStart(2, "0")}`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `productos_sat_${dateStr}.csv`);
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      successNotification("Catálogo exportado correctamente");
    } catch (error) {
      console.error("Error al exportar catálogo:", error);
      errorNotification("Ocurrió un error al exportar el catálogo");
    }
  };

  onAddClick = () => {
    this.setState({
      modalOpen: true,
      editingProducto: null
    });
  };

  onEditClick = (producto: ProductoSat) => {
    this.setState({
      modalOpen: true,
      editingProducto: producto
    });
  };

  onDeleteClick = (id: number) => {
    this.setState({
      confirmPopupOpen: true,
      confirmPopupId: id
    });
  };

  confirmDelete = async () => {
    const { confirmPopupId } = this.state;
    await this.props.desactivarProductoSAT(confirmPopupId);
    this.setState({
      confirmPopupOpen: false,
      confirmPopupId: null
    });
    this.fetchData();
  };

  render() {
    const hayDatos =
      this.props.productosSat && this.props.productosSat.length > 0;

    return (
      <Container className="mb-5">
        <FormBuilder<Inputs, Buttons>
          config={formularioBusquedaProductosSat}
          submit={this.submit}
          processing={this.props.searching}
        />

        <div className="d-flex justify-content-end mb-3">
          <Button
            color="primary"
            onClick={this.handleExport}
            disabled={!hayDatos || this.props.saving}
          >
            {this.props.saving ? "Exportando..." : "Exportar Catálogo"}
          </Button>
        </div>

        <ProductosSatTable
          page={this.props.productosSat || []}
          onAddClick={this.onAddClick}
          onEditClick={this.onEditClick}
          onDeleteClick={this.onDeleteClick}
        />

        <ProductoSatModal
          isOpen={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          dispatcher={this.props}
          producto={this.state.editingProducto}
          onProductoSaved={() => {
            this.fetchData();
          }}
          saving={this.props.saving}
        />

        <ConfirmModal
          title="Confirmar eliminación"
          message="¿Está seguro de eliminar este producto?"
          isOpen={this.state.confirmPopupOpen}
          ok={this.confirmDelete}
          cancel={() => this.setState({ confirmPopupOpen: false })}
        />
      </Container>
    );
  }
}
