import React from "react";
import LocalidadDuplicada from "../../../types/codigoPostal";

const messageNombre = `Se han encontrado localidades con el mismo nombre, no puede utilizarlo.`;

interface Props {
  duplicados: LocalidadDuplicada[];
}

const LocalidadesDuplicadasModal = ({ duplicados }: Props) => {
  if (!duplicados.length) {
    return null;
  }

  return (
    <div className="row alert alert-warning">
      {messageNombre}
      <div
        style={{
          background: "rgba(255, 255, 255, 0.8)",
          marginTop: "16px",
          width: "100%"
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Municipio</th>
              <th>Localidad</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {duplicados.map(localidad => (
              <tr key={localidad.localidadId}>
                <td>{localidad.nombreMunicipio}</td>
                <td>{localidad.nombreLocalidad}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LocalidadesDuplicadasModal;
