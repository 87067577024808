import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";

const PRODUCTOS_SAT_BASE_URL = "/api/catalogs/productos-sat";

export const clearProductosSat = (): IAction => {
  return {
    type: actionTypes.CLEAR_PRODUCTOS_SAT,
    payload: true
  };
};

export const getProductosSatActivos = (): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.GET_PRODUCTOS_SAT_ACTIVOS,
    urlComplement: `/catalogo`,
    method: "get"
  });
};

export const getProductosSatActivosCustom = (
  id: number | null,
  nombre: string | null,
  clave: string | null
): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.GET_PRODUCTOS_SAT_ACTIVOS_CUSTOM,
    urlComplement: `/catalogo/custom`,
    method: "get",
    params: {
      id: id,
      nombre: nombre,
      clave: clave
    }
  });
};

export const crearProductoSat = (body: any): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.POST_PRODUCTO_SAT,
    urlComplement: `/catalogo`,
    body: body,
    method: "post"
  });
};

export const actualizarProductoSat = (body: any): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.PUT_PRODUCTO_SAT,
    urlComplement: `/catalogo`,
    body: body,
    method: "put"
  });
};

export const desactivarProductoSAT = (id: number): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.DELETE_PRODUCTO_SAT,
    urlComplement: `/catalogo/${id}`,
    method: "delete"
  });
};

export const getProductosNombreClaveDuplicados = (clave: string): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.GET_PRODUCTOS_NOMBRE_CLAVE_DUPLICADOS,
    urlComplement: `/catalogo/duplicados/clave`,
    method: "get",
    params: {
      clave: clave
    }
  });
};

export const getProductosNombreDuplicados = (nombre: string): IAction => {
  return productosSatRestAction({
    actionType: actionTypes.GET_PRODUCTOS_NOMBRE_DUPLICADOS,
    urlComplement: `/catalogo/duplicados/nombre`,
    method: "get",
    params: {
      nombre: nombre
    }
  });
};

const productosSatRestAction = restActionFactory(
  `${constants.BASE_URL}${PRODUCTOS_SAT_BASE_URL}`
);
