import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IEmpresaConvenioSelectorBuilder from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelectorBuilder/IEmpresaConvenioBuilder";
import { MedioProspeccion } from "../../../types-business/User";
import { commonRegex } from "../../helpers/commonRegex";

export type Inputs =
  | "folio"
  | "esExtranjero"
  | "curp"
  | "nombre"
  | "primerApellido"
  | "segundoApellido"
  | "fechaNacimiento"
  | "correo"
  | "telefono"
  | "extension"
  | "medioProspeccion"
  | "sexo"
  | "convenio"
  | "precioMembresia"
  | "mantenimiento"
  | "precioMantenimiento"
  | "alert";
export type Buttons = "verificarCurp" | "guardar";
export type FormValue = { [key in Inputs]: any };

export const formConfigFactory: (
  curpRequired: boolean,
  curpApiActiva: boolean
) => FormBuilderConfig<Inputs, Buttons> = (
  curpRequired: boolean,
  curpApiActiva: boolean
) => ({
  inputs: [
    {
      name: "folio",
      label: "Folio",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 32
      },
      bts: {
        mCol: curpApiActiva ? 2 : 4
      }
    },
    {
      name: "esExtranjero",
      label: "¿Es extranjero?",
      component: ISelectBuilder,
      props: {
        emptyLabel: "No",
        data: [{ label: "Sí", value: "1" }]
      },
      bts: {
        mCol: curpApiActiva ? 2 : 4
      }
    },
    {
      name: "curp",
      label: curpRequired ? "* CURP" : "CURP",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 18
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "nombre",
      label: "* Nombre",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 128
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "primerApellido",
      label: "* Primer apellido",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 50
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "segundoApellido",
      label: "Segundo apellido",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 50
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "fechaNacimiento",
      label: "* Fecha de nacimiento",
      component: IDateBuilder,
      bts: {
        mCol: 4
      }
    },
    {
      name: "correo",
      label: "* Correo",
      component: ITextBuilder,
      props: {
        maxlength: 128
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "telefono",
      label: "* Teléfono",
      component: ITextBuilder,
      props: {
        type: "NUMERIC",
        maxlength: 10
      },
      bts: {
        mCol: 3
      }
    },
    {
      name: "extension",
      label: "Ext",
      component: ITextBuilder,
      props: {
        type: "NUMERIC",
        maxlength: 5
      },
      bts: {
        mCol: 1
      }
    },
    {
      name: "medioProspeccion",
      label: "* Medio de prospección",
      component: ISelectBuilder,
      bts: {
        mCol: 4
      },
      props: {
        data: Object.values(MedioProspeccion),
        mapOption: (option: string) => ({
          label: option.replace(/_/g, " "),
          value: option
        })
      }
    },
    {
      name: "sexo",
      label: "* Sexo",
      component: ISelectBuilder,
      bts: {
        mCol: 4
      },
      props: {
        emptyLabel: "Seleccione",
        data: [
          { label: "Masculino", value: "MASCULINO" },
          { label: "Femenino", value: "FEMENINO" }
        ]
      }
    },
    {
      name: "convenio",
      label: "Convenio",
      component: IEmpresaConvenioSelectorBuilder,
      props: {
        todas: true,
        ignorarConvenio: false
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "precioMembresia",
      label: "* Precio de membresía",
      component: ITextBuilder,
      props: {
        type: "NUMERIC",
        maxlength: 10
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "mantenimiento",
      label: "* Mantenimiento",
      component: ITextBuilder,
      props: {
        uppercase: true,
        maxlength: 50
      },
      bts: {
        mCol: 4
      }
    },
    {
      name: "precioMantenimiento",
      label: "* Precio de mantenimiento",
      component: ITextBuilder,
      props: {
        type: "NUMERIC",
        maxlength: 10
      },
      bts: {
        mCol: 4
      }
    }
  ],
  buttons: [
    {
      name: "verificarCurp",
      label: "* Verificar CURP",
      component: IButtonBuilder,
      bts: {
        mCol: 4
      }
    },
    {
      name: "guardar",
      label: "Guardar Prospecto",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        mCol: 12
      },
      props: {
        addTopMargin: false
      }
    }
  ],
  validations: {
    curp: curpRequired
      ? {
          required: true,
          curp: {
            validator: (value: string) =>
              value && !commonRegex.curp.test(value.toUpperCase()),
            message: () => "La CURP ingresada no es válida"
          }
        }
      : {
          curp: {
            validator: (value: string) =>
              value && !commonRegex.curp.test(value.toUpperCase()),
            message: () => "La CURP ingresada no es válida"
          }
        },
    nombre: {
      required: true
    },
    primerApellido: {
      required: true
    },
    fechaNacimiento: {
      required: true
    },
    correo: {
      required: true,
      email: true
    },
    telefono: {
      required: true,
      diezDigitos: {
        validator: (value: string) => !/^\d{10}$/.test(value),
        message: () => "El teléfono debe ser de 10 dígitos"
      }
    },
    medioProspeccion: {
      required: true
    },
    sexo: {
      required: true
    },
    precioMembresia: {
      required: true
    },
    mantenimiento: {
      required: true
    },
    precioMantenimiento: {
      required: true
    }
  },
  rows: [
    [
      "folio",
      "esExtranjero",
      "curp",
      "verificarCurp",
      "nombre",
      "primerApellido",
      "segundoApellido",
      "fechaNacimiento",
      "sexo",
      "medioProspeccion",
      "correo",
      "telefono",
      "extension",
      "convenio",
      "precioMembresia",
      "mantenimiento",
      "precioMantenimiento"
    ],
    ["alert"],
    ["guardar"]
  ]
});
