import React from "react";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../../../Home/components/common/DatePicker";
import IInput from "../iInput/IInput";
import IDateProps from "./IDate.props";
import IDateState from "./IDate.state";

export default class IDate extends IInput<IDateProps, IDateState> {
  state = {
    value: null
  };

  componentDidMount(): void {
    if (this.props.init) {
      this.setState(
        {
          value: this.props.init
        },
        () => super.triggerInput()
      );
    } else {
      super.triggerInput();
    }
  }

  componentDidUpdate(prevProps): void {
    if (this.props.init != prevProps.init) {
      this.setState({
        value: this.props.init
      });
    }
  }

  onChange = date => {
    const value = date && moment(date).format("YYYY-MM-DD");
    this.props.setter(this.props.name, value);
    this.props.onChange && this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    if (this.props.hidden) {
      return <></>;
    }

    const id = `${this.props.formKey || ""}-${this.props.name}`;
    return (
      <div className={this.btsClassName()}>
        <div style={{ display: "inline-block", width: "calc(100% - 25px)" }}>
          <DatePicker
            label={this.props.label}
            name={this.props.name}
            id={id}
            selected={this.state.value}
            onChange={this.onChange}
            errors={
              this.props.dirty && this.props.message
                ? [this.props.message]
                : null
            }
            disabled={this.props.disabled}
          />
        </div>
        {!this.props.disabled && (
          <UncontrolledTooltip placement="top" target={`${id}-borrar`}>
            Borrar fecha
          </UncontrolledTooltip>
        )}
        <div
          id={`${id}-borrar`}
          onClick={e => {
            e.preventDefault();

            if (!this.props.disabled) {
              this.onChange(null);
            }
          }}
          style={{
            cursor: this.props.disabled ? "default" : "pointer",
            color: this.props.disabled ? "#ccc" : "#000",
            textAlign: "right",
            display: "inline-block",
            width: "24px",
            height: "24px",
            verticalAlign: "top",
            paddingTop: "32px"
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
      </div>
    );
  }
}
