import React, { Component } from "react";
import { ReduxConnect, Router } from "../../../toolkit/decorators/Artifact";
import {
  ProspectoCrmModalDispatcher,
  prospectoCrmModalDispatcher
} from "./ProspectoCrmModal.dispatcher";
import {
  ProspectoCrmModalStore,
  prospectoCrmModalStore
} from "./ProspectoCrmModal.store";
import ProspectoCrm from "../../../types/ProspectoCrm";
import ProspectoCrmModal from "../../components/prospectoCrmModal";
import ModalFrame from "../../../toolkit/modalFrame";

interface Props extends ProspectoCrmModalStore, ProspectoCrmModalDispatcher {
  openModal?: boolean;
  onCloseModal?: () => void;
  onProspectSaved?: (prospect: ProspectoCrm) => void;
}

interface State {}

@Router()
@ReduxConnect(prospectoCrmModalStore, prospectoCrmModalDispatcher)
export default class ProspectoCrmModalContainer extends Component<
  Props,
  State
> {
  render() {
    return (
      <ModalFrame
        title="Crear Prospecto"
        isOpen={this.props.openModal}
        toggle={() => this.props.onCloseModal()}
        size="lg"
      >
        <ProspectoCrmModal {...this.props} />
      </ModalFrame>
    );
  }
}
