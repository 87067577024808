import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Alert, Button, Table } from "reactstrap";
import s from "./styles.scss";
import { ProspectArguments, Prospect } from "../../../types/Prospects";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import {
  errorNotification,
  successNotification,
  warnNotification
} from "../../../utils/notifications";
import { commonRegex } from "../../helpers/commonRegex";

interface Props {
  isFreePartner: boolean;
  isChangeFreePartner: boolean;
  changeFreePartnerData: any;
  isFreeChildPartner: boolean;
  persons: Prospect[];
  personByID: Prospect;
  history: any;
  match: any;
  partnerLinkError: any[];
  partnerLinking: boolean;
  membershipTypesMembers: {
    nombre: string;
    maximoIntegrantes: number;
    tipoIntegranteId: number;
  }[];
  searchClients: (args: ProspectArguments) => any;
  searchClientByID: (id: string) => any;
  getPersonByNumeroEmpleado: (id: string) => any;
  getNuevoSocioCrmParams: () => any;
  postNuevoSocioCrmRenapo: (body: object) => any;
  getNuevoSocioCrmRepetidosCurp: (curp: string) => any;
  postNuevoSocioCrmAsignacion: (body: object) => any;
}

enum SCREEN_MODES {
  SEARCHING,
  CREATING
}

enum Sexos {
  MASCULINO = "MASCULINO",
  FEMENINO = "FEMENINO"
}

interface Partner {
  entidadNacimiento: string;
  curp: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: any;
  sexo: string;
  bloqueoMail: boolean;
  bloqueoCallCenter: boolean;
  curpAprobada: boolean;
  curpPersona: string;
}

interface State {
  searchFields: {
    nombre: string;
    apellido: string;
    apellidoMaterno: string;
  };
  partnerFields: Partner;
  personaID: string;
  selectedPartner: string;
  screenMode: SCREEN_MODES;
  idTipoIntegrante: number;
  curpError: string;
  loading: boolean;
  gettingCurp: boolean;
  hasCurp: boolean;
  curpValidated: boolean;
  curpFormatValid: boolean;
  permisoOmitirCURP: boolean;
  consultarRenapo: boolean;
  showErrors: boolean;
}

const genreOptions = [
  { value: "", label: "Seleccione" },
  { value: "FEMENINO", label: "Femenino" },
  { value: "MASCULINO", label: "Masculino" }
];

const entidadNacimientoOptions = [
  { value: "", label: "Seleccione" },
  { value: "NACIONAL", label: "Nacional" },
  { value: "EXTRANJERO", label: "Extranjero" }
];

const TIPO_INTEGRANTE_TITULAR = 1;
const TIPO_INTEGRANTE_USUARIO_GRATIS = 17;
const TIPO_INTEGRANTE_NINO_GRATIS = 18;

class LinkPartner extends React.Component<Props, State> {
  state = {
    searchFields: {
      nombre: "",
      apellido: "",
      apellidoMaterno: ""
    },
    partnerFields: {
      entidadNacimiento: "",
      curp: null,
      nombre: "",
      primerApellido: "",
      segundoApellido: "",
      fechaNacimiento: null,
      sexo: "",
      bloqueoMail: false,
      bloqueoCallCenter: false,
      curpAprobada: true,
      curpPersona: "",
      curpValidated: false,
      curpFormatValid: false
    },
    personaID: "",
    selectedPartner: null,
    screenMode: SCREEN_MODES.SEARCHING,
    idTipoIntegrante: !(
      this.props.isFreePartner ||
      this.props.isFreeChildPartner ||
      this.props.isChangeFreePartner
    )
      ? null
      : !!this.props.isFreePartner || !!this.props.isChangeFreePartner
      ? TIPO_INTEGRANTE_USUARIO_GRATIS
      : TIPO_INTEGRANTE_NINO_GRATIS,
    curpError: "",
    loading: false,
    gettingCurp: false,
    hasCurp: false,
    curpValidated: false,
    curpFormatValid: false,
    permisoOmitirCURP: false,
    consultarRenapo: false,
    showErrors: false
  };

  componentDidMount() {
    this.props
      .getNuevoSocioCrmParams()
      .then(response => {
        const banderasPersonaResponse = response.value.data;
        this.setState({
          consultarRenapo: banderasPersonaResponse.apiCurpCrm,
          permisoOmitirCURP: banderasPersonaResponse.omitirCurp
          //consultarRenapo: true,
          //permisoOmitirCURP: false
        });

        if (this.state.permisoOmitirCURP) {
          /* successNotification(
            "Tiene permiso para omitir la validación de CURP"
          ); */
        } else {
          /* warnNotification(
            "No tiene permiso para omitir la validación de CURP. La validación es obligatoria."
          ); */
          /* if (!this.state.consultarRenapo) {
          warnNotification("La validación de CURP se realizará localmente sin consultar API externa.");
        } */
        }
      })
      .catch(err => {
        console.error("Error al obtener banderas de persona", err);
        errorNotification("Error al obtener banderas de persona");
      });
  }

  checkDuplicateCurps = curp => {
    return this.props
      .getNuevoSocioCrmRepetidosCurp(curp)
      .then(repetidosResponse => {
        const repetidos = repetidosResponse.value.data;

        if (repetidos && repetidos.length > 0) {
          const idsPersona = repetidos.map(rep => rep.idPersona).join(", ");
          const nombresCurp = repetidos
            .map(rep => rep.nombreCompleto)
            .join(", ");
          errorNotification(
            `CURP ya registrada. IDs de persona: ${idsPersona}. Nombres: ${nombresCurp}`
          );
        }

        return repetidos;
      })
      .catch(error => {
        console.error("Error al verificar repetidos CURP:", error);
        errorNotification("Error al verificar duplicados de CURP");
        return [];
      });
  };

  updateStateWithRenapoData = (renapoCurpResponse, sexoValue) => {
    this.setState({
      partnerFields: {
        ...this.state.partnerFields,
        nombre: renapoCurpResponse.nombre || this.state.partnerFields.nombre,
        primerApellido:
          renapoCurpResponse.primerApellido ||
          this.state.partnerFields.primerApellido,
        segundoApellido:
          renapoCurpResponse.segundoApellido ||
          this.state.partnerFields.segundoApellido,
        fechaNacimiento: renapoCurpResponse.fechaNacimiento,
        sexo: sexoValue,
        curp: this.state.partnerFields.curpPersona
      },
      hasCurp: true,
      gettingCurp: false,
      loading: false,
      curpValidated: true,
      curpFormatValid: true
    });
  };

  verifyCurp = e => {
    e.preventDefault();
    this.setState({ gettingCurp: true, loading: true });

    this.checkDuplicateCurps(this.state.partnerFields.curpPersona)
      .then(repetidos => {
        if (repetidos && repetidos.length > 0) {
          this.setState({
            gettingCurp: false,
            loading: false
          });
          return;
        }

        if (this.state.consultarRenapo) {
          this.props
            .postNuevoSocioCrmRenapo({
              curp: this.state.partnerFields.curpPersona,
              apiCurpEstatus: "NO_INTENTADO"
            })
            .then(curpResponse => {
              const renapoCurpResponse = curpResponse.value.data;
              let sexo = this.state.partnerFields.sexo;
              if (renapoCurpResponse.sexo) {
                if (renapoCurpResponse.sexo === "MASCULINO") {
                  sexo = Sexos.MASCULINO;
                } else if (renapoCurpResponse.sexo === "FEMENINO") {
                  sexo = Sexos.FEMENINO;
                }
              }
              this.updateStateWithRenapoData(renapoCurpResponse, sexo);
            })
            .catch(error => {
              console.error("Error al verificar CURP con API Externa:", error);
              this.setState({
                gettingCurp: false,
                loading: false,
                curpError:
                  "No fue posible validar la CURP. Verifique e intente nuevamente."
              });
              errorNotification("Error al validar CURP");
            });
        } else {
          const curpFormatValid = commonRegex.curp.test(
            this.state.partnerFields.curpPersona.toUpperCase()
          );
          this.setState({
            gettingCurp: false,
            loading: false,
            curpFormatValid,
            curpValidated: curpFormatValid
          });
          if (!curpFormatValid) {
            errorNotification("El formato de la CURP no es válido");
          } else {
            successNotification(
              "Formato CURP validado correctamente localmente"
            );
          }
        }
      })
      .catch(error => {
        console.error("Error al verificar duplicados de CURP:", error);
        this.setState({
          gettingCurp: false,
          loading: false,
          curpError: "Ocurrió un error al verificar si la CURP ya existe"
        });
        errorNotification("Error al verificar CURP");
      });
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        return { ...state, searchFields };
      });
    };
  };

  onChangePartnerFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "fechaNacimiento") {
        value = event;
      } else {
        value = (event.target.value || "").toUpperCase();
      }

      let curpFormatValid = this.state.curpFormatValid;
      let curpValidated = this.state.curpValidated;
      let hasCurp = this.state.hasCurp;
      let updatedPartnerFields = { ...this.state.partnerFields };

      if (fieldName === "curpPersona" && !this.state.permisoOmitirCURP) {
        curpFormatValid = value && commonRegex.curp.test(value);

        if (this.state.curpValidated) {
          curpValidated = false;

          if (this.state.consultarRenapo && this.state.hasCurp) {
            hasCurp = true;
            updatedPartnerFields = {
              ...updatedPartnerFields,
              nombre: "",
              primerApellido: "",
              segundoApellido: "",
              fechaNacimiento: null,
              sexo: ""
            };
          } else {
            hasCurp = false;
          }
        }
      }

      updatedPartnerFields[fieldName] = value;

      this.setState({
        partnerFields: updatedPartnerFields,
        curpFormatValid:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? curpFormatValid
            : this.state.curpFormatValid,
        curpValidated:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? curpValidated
            : this.state.curpValidated,
        hasCurp:
          fieldName === "curpPersona" && !this.state.permisoOmitirCURP
            ? hasCurp
            : this.state.hasCurp
      });
    };
  };

  getMemberTypesCatalog = () => {
    if (
      !(
        this.props.isFreePartner ||
        this.props.isChangeFreePartner ||
        this.props.isFreeChildPartner
      )
    ) {
      return [
        {
          nombre: "Seleccione...",
          tipoIntegranteId: null
        }
      ].concat(
        (this.props.membershipTypesMembers || []).filter(
          x =>
            !!x.maximoIntegrantes &&
            x.tipoIntegranteId != TIPO_INTEGRANTE_TITULAR
        )
      );
    } else if (!!this.props.isFreePartner || !!this.props.isChangeFreePartner) {
      return [
        {
          nombre: "USUARIO_GRATIS",
          tipoIntegranteId: TIPO_INTEGRANTE_USUARIO_GRATIS
        }
      ];
    } else if (!!this.props.isFreeChildPartner) {
      return [
        {
          nombre: "NIÑO_GRATIS",
          tipoIntegranteId: TIPO_INTEGRANTE_NINO_GRATIS
        }
      ];
    }

    return [];
  };

  searchClients = e => {
    e.preventDefault();
    const args = { ...this.state.searchFields };

    this.setState({ loading: true });

    if (this.state.personaID) {
      this.props.searchClientByID(this.state.personaID);

      setTimeout(() => {
        if (!this.props.personByID) {
          errorNotification(
            `No se encontró persona con ID: ${this.state.personaID}`
          );
        }
        this.setState({ loading: false });
      }, 1000);
    } else {
      this.props.searchClients(args);

      setTimeout(() => {
        if (!this.props.persons || this.props.persons.length === 0) {
          errorNotification("No se encontraron resultados para la búsqueda");
        }
        this.setState({ loading: false });
      }, 1000);
    }

    this.setState({ screenMode: SCREEN_MODES.SEARCHING });
  };

  newParter = e => {
    e.preventDefault();
    this.setState({ screenMode: SCREEN_MODES.CREATING });
  };

  onChangePersonaID = event => {
    const personaID = event.target.value;
    this.setState({ ...this.state, personaID });
  };

  selectPartner = id => {
    this.setState({ selectedPartner: id });
  };

  onChangePartnerType = event => {
    event.preventDefault();
    const idTipoIntegrante = event.target.value;
    this.setState({ idTipoIntegrante });
  };

  getPersonsRender = () => {
    const personsToRender =
      ((this.state.searchFields.apellidoMaterno ||
        this.state.searchFields.apellido ||
        this.state.searchFields.nombre) &&
        this.props.persons &&
        this.props.persons.length &&
        this.props.persons) ||
      (this.state.personaID &&
        this.props.personByID && [this.props.personByID]);
    return personsToRender && personsToRender.length ? (
      <Table size="sm" hover className="table-borderless">
        <thead>
          <tr>
            <th>PersonaID</th>
            <th>Nombre</th>
            <th>Apellido P.</th>
            <th>Apellido M.</th>
            <th>Fecha de Nacimiento</th>
            <th>Teléfono</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          {" "}
          {personsToRender && personsToRender.length
            ? personsToRender.map(prospect => (
                <tr
                  className={s.partnerRow}
                  style={
                    prospect.personaId === this.state.selectedPartner
                      ? { backgroundColor: "rgba(0, 0, 0, 0.075)" }
                      : {}
                  }
                  onClick={() => this.selectPartner(prospect.personaId)}
                  key={prospect.personaId}
                >
                  <th scope="row">{prospect.personaId}</th>
                  <td>{prospect.nombre}</td>
                  <td>{prospect.primerApellido}</td>
                  <td>{prospect.segundoApellido}</td>
                  <td>{prospect.fechaNacimiento}</td>
                  <td>
                    {prospect.telefonos &&
                      prospect.telefonos.length &&
                      prospect.telefonos[0].numero}
                  </td>
                  <td>
                    {prospect.correosElectronicos &&
                      prospect.correosElectronicos.length &&
                      prospect.correosElectronicos[0].correo}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    ) : null;
  };

  validCreatingForm = () => {
    const requiredFields = [
      "nombre",
      "primerApellido",
      /* "segundoApellido", */
      "fechaNacimiento",
      "sexo",
      "entidadNacimiento"
    ];

    if (!this.state.permisoOmitirCURP) {
      requiredFields.push("curpPersona");
    }

    const missingFields = requiredFields.filter(
      field =>
        !this.state.partnerFields[field] ||
        (typeof this.state.partnerFields[field] === "string" &&
          this.state.partnerFields[field].trim() === "")
    );

    const curpValid =
      this.state.permisoOmitirCURP ||
      (this.state.partnerFields.curpPersona && this.state.curpValidated);

    return missingFields.length === 0 && curpValid;
  };

  linkPartner = e => {
    e.preventDefault();

    this.setState({ showErrors: true });

    if (this.state.screenMode === SCREEN_MODES.SEARCHING) {
      if (!this.state.selectedPartner) {
        errorNotification("Debe seleccionar un socio para continuar");
        return;
      }
    } else if (this.state.screenMode === SCREEN_MODES.CREATING) {
      if (!this.state.idTipoIntegrante) {
        errorNotification("Debe seleccionar un tipo de socio");
        return;
      }
      if (!this.validCreatingForm()) {
        errorNotification(
          "Por favor complete todos los campos requeridos correctamente"
        );
        return;
      }
    }

    const { idMembresiaSocio } = this.props.match.params;

    const tipoOperacion =
      this.props.isFreePartner ||
      this.props.isFreeChildPartner ||
      this.props.isChangeFreePartner
        ? "INVITADO_GRATIS"
        : "NUEVO_SOCIO";

    const requestBody = {
      idMembresiaSocio: Number(idMembresiaSocio),
      tipo: tipoOperacion,
      idTipoIntegrante: Number(this.state.idTipoIntegrante),
      idPersona: null,
      personaNueva: null
    };

    if (
      this.state.screenMode === SCREEN_MODES.SEARCHING &&
      this.state.selectedPartner
    ) {
      requestBody.idPersona = Number(this.state.selectedPartner);
    } else if (this.state.screenMode === SCREEN_MODES.CREATING) {
      const fechaNacimiento = moment(
        this.state.partnerFields.fechaNacimiento
      ).format("YYYY-MM-DD");

      requestBody.personaNueva = {
        nombre: this.state.partnerFields.nombre,
        primerApellido: this.state.partnerFields.primerApellido,
        segundoApellido: this.state.partnerFields.segundoApellido,
        fechaNacimiento: fechaNacimiento,
        sexo: this.state.partnerFields.sexo,
        extranjero: this.state.partnerFields.entidadNacimiento === "EXTRANJERO",
        curp: this.state.partnerFields.curpPersona,
        curpAprobada: this.state.curpValidated,
        apiCurpEstatus: this.state.curpValidated ? "EXITOSO" : "NO_INTENTADO",
        apiCurpError: this.state.curpError || null
      };
    }

    this.setState({ loading: true });
    this.props
      .postNuevoSocioCrmAsignacion(requestBody)
      .then(response => {
        this.setState({ loading: false });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error => {
        this.setState({ loading: false });
        console.error("Error al asociar socio:", error);

        if (error.response) {
          if (error.response.data && error.response.data.message) {
            errorNotification(error.response.data.message);
          } else if (
            error.response.data &&
            typeof error.response.data === "string"
          ) {
            errorNotification(error.response.data);
          } else {
            errorNotification(
              "Error al procesar la solicitud. Verifique los datos e intente nuevamente."
            );
          }
        } else {
          errorNotification(
            "Error de comunicación con el servidor. Verifique su conexión e intente nuevamente."
          );
        }
      });
  };

  render() {
    return (
      <div className={s.modalContainer}>
        <div className="h-100">
          <form className={s.basicDataClub}>
            <div className="form-row">
              {!(
                this.props.isFreePartner ||
                this.props.isFreeChildPartner ||
                this.props.isChangeFreePartner
              ) && (
                <div className="col-md-2">
                  <BaseInput
                    label={"Tipo de Socio"}
                    name="idTipoIntegrante"
                    type="select"
                    id="idTipoIntegrante"
                    placeholder="Tipo de Socio"
                    value={this.state.idTipoIntegrante}
                    options={this.getMemberTypesCatalog().map(
                      ({ tipoIntegranteId, nombre }) => ({
                        label: nombre,
                        value: tipoIntegranteId
                      })
                    )}
                    onChange={e => this.onChangePartnerType(e)}
                  />
                </div>
              )}

              <div className="col-md-2">
                <BaseInput
                  label={"Persona Id"}
                  name="personaID"
                  type="number"
                  id="personaID"
                  placeholder="Persona Id"
                  value={this.state.personaID}
                  onChange={e => this.onChangePersonaID(e)}
                  disabled={
                    this.state.searchFields.apellido ||
                    this.state.searchFields.nombre
                  }
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"* Nombre"}
                  name="nombre"
                  type="text"
                  id="nombre"
                  placeholder="Nombre"
                  value={this.state.searchFields.nombre}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("nombre")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"* A. Paterno"}
                  name="apellido"
                  type="text"
                  id="apellido"
                  placeholder="A. Paterno"
                  value={this.state.searchFields.apellido}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellido")}
                />
              </div>
              <div className="col">
                <BaseInput
                  type="text"
                  label={"A. Materno"}
                  name="apellidoMaterno"
                  id="apellidoapellidoMaterno"
                  placeholder="A. Materno"
                  value={this.state.searchFields.apellidoMaterno}
                  disabled={this.state.personaID}
                  onChange={this.onChangeSearchFieldsFactory("apellidoMaterno")}
                />
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  onClick={e => this.searchClients(e)}
                  size="sm"
                  block
                  disabled={
                    (!this.state.personaID &&
                      !(
                        this.state.searchFields.apellido &&
                        this.state.searchFields.nombre
                      )) ||
                    this.state.loading
                  }
                >
                  {!this.state.loading ? "Buscar" : "Espere"}
                </Button>
              </div>
              <div className="col-1">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                  size="sm"
                  block
                  onClick={e => this.newParter(e)}
                >
                  {"Nuevo"}
                </Button>
              </div>
            </div>
          </form>
          <hr />
          {this.state.screenMode === SCREEN_MODES.SEARCHING ? (
            this.getPersonsRender()
          ) : this.state.screenMode === SCREEN_MODES.CREATING ? (
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nacionalidad"}
                    name="entidadNacimiento"
                    type="select"
                    options={entidadNacimientoOptions}
                    id="entidadNacimiento"
                    placeholder="Entidad Nacimiento"
                    value={this.state.partnerFields.entidadNacimiento}
                    onChange={this.onChangePartnerFieldsFactory(
                      "entidadNacimiento"
                    )}
                    errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.entidadNacimiento ||
                        this.state.partnerFields.entidadNacimiento === "")
                        ? ["Debe seleccionar una entidad de nacimiento"]
                        : []
                    }
                  />
                </div>
                {!this.state.permisoOmitirCURP && (
                  <>
                    <div className="col">
                      <BaseInput
                        label={"CURP"}
                        name="curpPersona"
                        type="text"
                        id="curpPersona"
                        placeholder="CURP"
                        value={this.state.partnerFields.curpPersona}
                        onChange={this.onChangePartnerFieldsFactory(
                          "curpPersona"
                        )}
                        errors={
                          this.state.partnerFields.curpPersona &&
                          !this.state.curpFormatValid &&
                          !this.state.permisoOmitirCURP
                            ? ["La CURP ingresada no es válida"]
                            : this.state.showErrors &&
                              (!this.state.partnerFields.curpPersona ||
                                this.state.partnerFields.curpPersona === "") &&
                              !this.state.permisoOmitirCURP
                            ? ["Este campo es obligatorio"]
                            : []
                        }
                      />
                    </div>
                    <div className="col-2">
                      <Button
                        className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                        size="sm"
                        block
                        onClick={this.verifyCurp}
                        disabled={
                          !this.state.partnerFields.curpPersona ||
                          !this.state.partnerFields.entidadNacimiento ||
                          this.state.partnerFields.entidadNacimiento === "" ||
                          this.state.loading ||
                          (this.state.curpValidated &&
                            this.state.consultarRenapo) ||
                          (!this.state.permisoOmitirCURP &&
                            !this.state.curpFormatValid)
                        }
                      >
                        {this.state.curpValidated
                          ? "CURP validado"
                          : !this.state.loading
                          ? "Verificar CURP"
                          : "Verificando..."}
                      </Button>
                    </div>
                  </>
                )}
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    uppercase={true}
                    value={this.state.partnerFields.nombre}
                    onChange={this.onChangePartnerFieldsFactory("nombre")}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.nombre ||
                        this.state.partnerFields.nombre === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Apellido Paterno"}
                    name="primerApellido"
                    type="text"
                    id="primerApellido"
                    placeholder="Apellido Paterno"
                    uppercase={true}
                    value={this.state.partnerFields.primerApellido}
                    onChange={this.onChangePartnerFieldsFactory(
                      "primerApellido"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.primerApellido ||
                        this.state.partnerFields.primerApellido === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    }
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Apellido Materno"}
                    name="segundoApellido"
                    type="text"
                    id="segundoApellido"
                    placeholder="Apellido Materno"
                    uppercase={true}
                    value={this.state.partnerFields.segundoApellido}
                    onChange={this.onChangePartnerFieldsFactory(
                      "segundoApellido"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    /* errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.segundoApellido ||
                        this.state.partnerFields.segundoApellido === "")
                        ? ["Este campo es obligatorio"]
                        : []
                    } */
                  />
                </div>
                <div className="col">
                  <DatePicker
                    label="Fecha de Nacimiento"
                    name="fechaNacimiento"
                    id="fechaNacimiento"
                    selected={this.state.partnerFields.fechaNacimiento}
                    onChange={this.onChangePartnerFieldsFactory(
                      "fechaNacimiento"
                    )}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.fechaNacimiento ||
                        this.state.partnerFields.fechaNacimiento === null)
                        ? ["La fecha de nacimiento es obligatoria"]
                        : []
                    }
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Sexo"}
                    name="sexo"
                    type="select"
                    options={genreOptions}
                    id="sexo"
                    placeholder="Sexo"
                    value={this.state.partnerFields.sexo}
                    onChange={this.onChangePartnerFieldsFactory("sexo")}
                    disabled={
                      !(
                        this.state.permisoOmitirCURP ||
                        (this.state.curpValidated &&
                          !this.state.consultarRenapo)
                      )
                    }
                    errors={
                      this.state.showErrors &&
                      (!this.state.partnerFields.sexo ||
                        this.state.partnerFields.sexo === "")
                        ? ["Debe seleccionar un sexo"]
                        : []
                    }
                  />
                </div>
              </div>
            </form>
          ) : null}
        </div>
        <form>
          <div className="form-row p-0 mb-3">
            <div className="col-12">
              {this.props.partnerLinkError &&
                this.props.partnerLinkError.map((message, index) => {
                  return (
                    <div key={index} className="alert alert-danger mt-3">
                      {message}
                    </div>
                  );
                })}
            </div>
            <div className="col-1 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.linkPartner(e)}
                disabled={this.state.loading}
                size="sm"
                block
              >
                {this.state.screenMode === SCREEN_MODES.SEARCHING
                  ? "Asociar"
                  : "Guardar"}
              </Button>
            </div>
          </div>
        </form>
        {false && (
          <span>
            <hr />
            <Alert color="danger">
              Ocurrio un error creando el club. Intente nuevamente.
            </Alert>
          </span>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(LinkPartner));
