import React from "react";

interface Props {}

interface State {}

class SignatureTest extends React.Component<Props, State> {
  /* render() {
    return <div>HELLO</div>;
  } */
  render() {
    return (
      <div>
        <form
          name="test"
          method="post"
          action="https://qa3.mitec.com.mx/pgs/MiTokenU"
        >
          <h3>Página de Pruebas MiToken</h3>
          <table width="95%" align="center" cellpadding="2" cellspacing="0">
            <tr>
              <td>XMLMTKN:</td>
              <td>
                <textarea rows="17" cols="100" name="xmlmtkn"></textarea>
              </td>
            </tr>
            <tr>
              <td>Referencia:</td>
              <td>
                <input
                  type="text"
                  name="referencia"
                  id="referencia"
                  size="50"
                  maxlength="30"
                />
              </td>
            </tr>
            <tr>
              <td>Texto botón enviar:</td>
              <td>
                <input
                  type="text"
                  name="nbBotonEnviar"
                  id="nbBotonEnviar"
                  size="50"
                  maxlength="30"
                />
              </td>
            </tr>
            <tr>
              <td>Leyenda Exito:</td>
              <td>
                <input
                  type="text"
                  name="txRespExito"
                  id="txRespExito"
                  size="50"
                  maxlength="50"
                />
              </td>
            </tr>
            <tr>
              <td>Leyenda Error:</td>
              <td>
                <input
                  type="text"
                  name="txRespError"
                  id="txRespError"
                  size="50"
                  maxlength="50"
                />
              </td>
            </tr>
            <tr>
              <td>Datos Adicionales TDC en la Respuesta:</td>
              <td>
                <input
                  type="text"
                  name="stRespDatosTDC"
                  id="stRespDatosTDC"
                  size="10"
                  maxlength="1"
                />
              </td>
            </tr>
            <tr>
              <td>URL Respuesta:</td>
              <td>
                <input
                  type="text"
                  name="urlRespuesta"
                  id="urlRespuesta"
                  size="100"
                />
              </td>
            </tr>
          </table>
          <p align="center">
            <input
              type="submit"
              name="enviar"
              value="Enviar Prueba"
              class="boton"
            />
          </p>
        </form>
      </div>
    );
  }
}

export default SignatureTest;
