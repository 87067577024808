import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const DOCUMENTATION_URL = "/api/documentacion/main/menu";

export const getDocumentation = (): IAction => ({
  type: actionTypes.GET_DOCUMENTATION,
  payload: {
    promise: axios.get(`${constants.BASE_URL}${DOCUMENTATION_URL}`, {
      withCredentials: true
    })
  }
});
