import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { errorNotification } from "../../utils/notifications";

interface State {
  documentation: any;
  loading: boolean;
  error: any;
}

const initialState: State = {
  documentation: null,
  loading: false,
  error: null
};

export default function landingPageReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_DOCUMENTATION}_PENDING`:
      return { ...state, loading: true, error: null };
    case `${actionTypes.GET_DOCUMENTATION}_REJECTED`:
      errorNotification(action.payload.message);
      return {
        ...state,
        documentation: null,
        loading: false,
        error: getErrorMessage(action.payload)
      };
    case `${actionTypes.GET_DOCUMENTATION}_FULFILLED`:
      return {
        ...state,
        documentation: action.payload.data,
        loading: false,
        error: null
      };
    default:
      return state;
  }
}
