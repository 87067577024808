import { Preview } from "../../../types/IAction";
import ProspectoCrm from "../../../types/ProspectoCrm";
import * as actions from "../../actions/prospectosV2";
import {
  GetProspectoCrmParams,
  GetProspectoCrmRenapo,
  PostProspectoCrm,
  GetProspectosCrmRepetidosCurp,
  GetProspectosCrmRepetidosCorreo
} from "../../reducers/prospectosV2";

export interface ProspectoCrmModalDispatcher {
  clearProspectoCrm: () => Promise<Preview<void>>;
  getProspectoCrmParams: () => Promise<Preview<GetProspectoCrmParams>>;
  getProspectoCrmRenapo: (
    prospecto: ProspectoCrm
  ) => Promise<Preview<GetProspectoCrmRenapo>>;
  postProspectoCrm: (
    prospecto: ProspectoCrm
  ) => Promise<Preview<PostProspectoCrm>>;
  getProspectosCrmRepetidosCurp: (
    curp: string
  ) => Promise<Preview<GetProspectosCrmRepetidosCurp>>;
  getProspectosCrmRepetidosCorreo: (
    correo: string
  ) => Promise<Preview<GetProspectosCrmRepetidosCorreo>>;
}

export const prospectoCrmModalDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ProspectoCrmModalDispatcher = dispatch => ({
  clearProspectoCrm: () => {
    return dispatch(actions.clearProspectoCrm());
  },
  getProspectoCrmParams: () => {
    return dispatch(actions.getProspectoCrmParams());
  },
  getProspectoCrmRenapo: (prospecto: ProspectoCrm) => {
    return dispatch(actions.getProspectoCrmRenapo(prospecto));
  },
  postProspectoCrm: (prospecto: ProspectoCrm) => {
    return dispatch(actions.postProspectoCrm(prospecto));
  },
  getProspectosCrmRepetidosCurp: (curp: string) => {
    return dispatch(actions.getProspectosCrmRepetidosCurp(curp));
  },
  getProspectosCrmRepetidosCorreo: (correo: string) => {
    return dispatch(actions.getProspectosCrmRepetidosCorreo(correo));
  }
});
