import React from "react";
import ProspectoRepetido from "../../../types/ProspectoRepetido";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const messageCorreo = `
 Se han encontrado clientes repetidos con el mismo correo, puede utilizarlo,
 pero no se recomienda.`;

const messageCurp = `
 La CURP del prospecto que intenta registrar ya está en uso.`;

interface Props {
  repetidos: ProspectoRepetido[];
  error: boolean;
  onClick: (row: ProspectoRepetido) => void;
}

const ProspectoCrmModalRepetidos = ({ repetidos, error, onClick }: Props) => {
  if (!repetidos.length) {
    return null;
  }

  return (
    <div className={`row alert alert-${error ? "danger" : "warning"}`}>
      {error ? messageCurp : messageCorreo}
      <div
        style={{
          background: "rgba(255, 255, 255, 0.8)",
          marginTop: "16px",
          width: "100%"
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Id</th>
              <th>Nombre</th>
              <th>Correo principal</th>
              <th>Teléfono</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {repetidos.map(repetido => (
              <tr key={repetido.idPersona}>
                <td>{repetido.idPersona}</td>
                <td>{repetido.nombreCompleto}</td>
                <td>{repetido.correo}</td>
                <td>{repetido.telefono}</td>
                <td>
                  <UncontrolledTooltip
                    placement="top"
                    target={`repetido-${repetido.idPersona}`}
                  >
                    Ir a carrito
                  </UncontrolledTooltip>
                  <div
                    id={`repetido-${repetido.idPersona}`}
                    onClick={e => {
                      e.preventDefault();
                      onClick(repetido);
                    }}
                    style={{
                      cursor: "pointer",
                      textAlign: "right",
                      display: "inline-block",
                      width: "24px"
                    }}
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProspectoCrmModalRepetidos;
