import React from "react";
import ProductoSat from "../../../types/ProductoSat";

const messageNombre = `
 Se han encontrado productos con el mismo nombre, no puede utilizarlo.`;

const messageClave = `
 Se han encontrado productos con la misma clave, no puede utilizarlo.`;

interface Props {
  duplicados: ProductoSat[];
  esNombre: boolean;
}

const ProductosDuplicadosModal = ({ duplicados, esNombre }: Props) => {
  if (!duplicados.length) {
    return null;
  }

  return (
    <div className="row alert alert-warning">
      {esNombre ? messageNombre : messageClave}
      <div
        style={{
          background: "rgba(255, 255, 255, 0.8)",
          marginTop: "16px",
          width: "100%"
        }}
      >
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Id</th>
              <th>{esNombre ? "Nombre" : "Clave"}</th>
              <th>{!esNombre ? "Nombre" : "Clave"}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {duplicados.map(producto => (
              <tr key={producto.id}>
                <td>{producto.id}</td>
                <td>{esNombre ? producto.nombre : producto.claveProducto}</td>
                <td>{!esNombre ? producto.nombre : producto.claveProducto}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductosDuplicadosModal;
