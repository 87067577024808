import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import AlphanumericInput from "../../../../toolkit/alphanumericInput";
import BaseAlphanumericInput from "../../../../toolkit/baseAlphanumericInput";
import BaseInput from "../../../../toolkit/baseInput";
import { handleRequestError } from "../../../../utils/helpers";
import { errorNotification } from "../../../../utils/notifications";
import { getFolioInfo } from "../../../actions/billing";
import s from "./styles.scss";

const ADM_CANCELACION_TIPO_RELACION = [
  { value: "", label: "Seleccione un tipo de relación" },
  { value: "01", label: "Nota de crédito de los documentos relacionados" },
  { value: "02", label: "Nota de débito de los documentos relacionados" },
  {
    value: "03",
    label: "Devolución de mercancia sobre facturas o traslados previos"
  },
  { value: "04", label: "Sustitución de CFDI previos" },
  { value: "05", label: "Traslados de mercancias facturadas previamente" },
  { value: "06", label: "Factura generada por los traslados previos" },
  { value: "07", label: "CFDI por aplicación de anticipo" }
];

const ADM_CANCELACION_MOTIVOS = [
  { value: "", label: "Seleccione un motivo de cancelación" },
  { value: "01", label: "Comprobante emitido con errores con relación" },
  { value: "02", label: "Comprobante emitido con errores sin relación" },
  { value: "03", label: "No se llevó a cabo la operación" },
  {
    value: "04",
    label: "Operación nominativa relacionada con una factura global"
  }
];

const FACTURAS_ESTATUS_TIMBRADA = ["TIMBRADA", "ENVIADA_ERP"];

export interface AdmRelatedFolio {
  key: String;
  id: String;
  folio: String;
  uuid: String;
  estatus: String;
  tipoRelacion: String;
  tipoRelacionDesc: String;
  motivo: String;
  motivoDesc: String;
}

interface Props {
  updateRelatedFolios: (data: AdmRelatedFolio[]) => void;
}

interface State {
  relatedFolios: AdmRelatedFolio[];
  relatedFolioData: AdmRelatedFolio;
  cancelacionFolio: String;
  cancelacionTipoRelacion: String;
  cancelacionMotivo: String;
}

class AdmRelatedFolios extends React.Component<Props, State> {
  state = {
    relatedFolios: [],
    relatedFolioData: null,
    cancelacionFolio: null,
    cancelacionTipoRelacion: "",
    cancelacionMotivo: ""
  };

  addRelatedFolio = () => {
    const {
      relatedFolioData,
      relatedFolios,
      cancelacionTipoRelacion,
      cancelacionMotivo
    } = this.state;

    if (!relatedFolioData || !cancelacionMotivo || !cancelacionTipoRelacion) {
      errorNotification("Se ingresar los datos solicitados");
      return;
    }

    relatedFolioData["tipoRelacion"] = cancelacionTipoRelacion;
    const tmpTipoRelacion = ADM_CANCELACION_TIPO_RELACION.find(
      x => x.value == cancelacionTipoRelacion
    );
    if (!!tmpTipoRelacion) {
      relatedFolioData["tipoRelacionDesc"] = tmpTipoRelacion.label;
    }

    relatedFolioData["motivoCancelacion"] = cancelacionMotivo;
    const tmpMotivo = ADM_CANCELACION_MOTIVOS.find(
      x => x.value == cancelacionMotivo
    );
    if (!!tmpMotivo) {
      relatedFolioData["motivoDesc"] = tmpMotivo.label;
    }

    console.info(relatedFolioData);

    this.setState({
      relatedFolios: [...relatedFolios, { ...relatedFolioData }]
    });

    if (!!this.props.updateRelatedFolios) {
      this.props.updateRelatedFolios([...relatedFolios, relatedFolioData]);
    }

    this.clearFieldsValues();
  };

  validateFolio = () => {
    this.setState({
      cancelacionMotivo: "",
      cancelacionTipoRelacion: "",
      relatedFolioData: null
    });

    const { cancelacionFolio } = this.state;

    if (!cancelacionFolio) {
      errorNotification("Debe ingresar un folio válido");
      return;
    }

    getFolioInfo(cancelacionFolio)
      .then(({ data }) => {
        console.info(data);

        if (!!data) {
          const { facturaId, folioReferencia, timbradoUUID, estatus } = data;

          if (FACTURAS_ESTATUS_TIMBRADA.indexOf(estatus) >= 0) {
            this.setState({
              relatedFolioData: {
                key: Math.floor(Math.random() * 100000 + 1),
                id: facturaId,
                folio: folioReferencia,
                uuid: timbradoUUID,
                estatus
              }
            });
          } else {
            errorNotification(
              "La factura correspondiente al folio ingresado no tiene un estatus válido para relacionar: " +
                estatus
            );
          }
        } else {
          errorNotification(
            "La factura correspondiente al folio ingresado no existe"
          );
        }
      })
      .catch(handleRequestError);
  };

  deleteRelatedFolio = key => {
    const relatedFolios = [
      ...this.state.relatedFolios.filter(x => x.key != key)
    ];
    this.setState({
      relatedFolios
    });

    if (!!this.props.updateRelatedFolios) {
      this.props.updateRelatedFolios([...relatedFolios]);
    }
  };

  clearFieldsValues = () => {
    this.setState({
      relatedFolioData: null,
      cancelacionFolio: null,
      cancelacionTipoRelacion: "",
      cancelacionMotivo: ""
    });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col xs={3} className="pr-0 pt-2">
            <FormGroup>
              <Label className={s.label} for={"admConfigCancFolio"}>
                Folio:
              </Label>
              <div className="input-group mb-3">
                <AlphanumericInput
                  label={"Folio:"}
                  id="admConfigCancFolio"
                  name="admConfigCancFolio"
                  type="text"
                  value={this.state.cancelacionFolio || ""}
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ cancelacionFolio: value });
                  }}
                  maxlength={10}
                  size={"xs"}
                />
                <div
                  className="input-group-append"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.validateFolio();
                  }}
                >
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                </div>
              </div>
            </FormGroup>
          </Col>

          <Col xs={4} md={3} className="pr-0 pt-2">
            <BaseAlphanumericInput
              label={"UUID:"}
              id="admConfigCancUUIDFactprevia"
              name="admConfigCancUUIDFactprevia"
              type="text"
              value={(this.state.relatedFolioData || {}).uuid || ""}
              maxlength={50}
              disabled={true}
            />
          </Col>
          <Col xs={6} md={2} className="pr-0 pt-2">
            <BaseInput
              label={"Tipo relación:"}
              id="admConfigCancTipoRelacionFactprevia"
              name="admConfigCancTipoRelacionFactprevia"
              type="select"
              value={this.state.cancelacionTipoRelacion}
              options={[...ADM_CANCELACION_TIPO_RELACION]}
              onChange={e => {
                const value = e.target.value;
                this.setState({ cancelacionTipoRelacion: value });
              }}
              disabled={!this.state.relatedFolioData}
            />
          </Col>

          <Col xs={6} md={2} className="pr-0 pt-2">
            <BaseInput
              label={"Motivo cancelación:"}
              id="admConfigCancMotivoFactprevia"
              name="admConfigCancMotivoFactprevia"
              type="select"
              value={this.state.cancelacionMotivo}
              options={[...ADM_CANCELACION_MOTIVOS]}
              onChange={e => {
                const value = e.target.value;
                this.setState({ cancelacionMotivo: value });
              }}
              disabled={!this.state.relatedFolioData}
            />
          </Col>

          <Col xs="1" className="text-center">
            <br />
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.addRelatedFolio()}
              size="xs"
              disabled={
                !this.state.relatedFolioData &&
                !this.state.cancelacionMotivo &&
                !this.state.cancelacionTipoRelacion
              }
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Row>

        <div className="mt-2">
          <ReactTable
            data={this.state.relatedFolios || []}
            columns={[
              {
                Header: "Folio",
                accessor: "folio",
                className: "text-center"
              },
              {
                Header: "UUID",
                accessor: "uuid"
              },
              {
                Header: "Estatus",
                accessor: "estatus",
                className: "text-center"
              },
              {
                Header: "Tipo relación",
                accessor: "tipoRelacionDesc"
              },
              {
                Header: "Motivo cancelación",
                accessor: "motivoDesc"
              },
              {
                Header: "",
                Cell: row => {
                  return (
                    <div className="w-100 text-center">
                      <Button
                        color="link"
                        onClick={() =>
                          this.deleteRelatedFolio(row.original.key)
                        }
                        className={`${s.innerButton} ${s.primaryLink}`}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </div>
                  );
                }
              }
            ]}
            className="-striped -highlight"
            sortable={false}
            showPagination={false}
            pageSize={(this.state.relatedFolios || []).length + 1}
            noDataText={""}
          />
        </div>
      </Container>
    );
  }
}

export default withStyles(s)(AdmRelatedFolios);
