import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import ProductoSat from "../../types/ProductoSat";
import { successNotification } from "../../utils/notifications";

export interface GetProductosSat {
  productosSat: ProductoSat[];
}

export interface CrearProductoSat {
  name: string;
  claveProducto: string;
}

export interface ActualizarProductoSat {
  id: number;
  name: string;
  claveProducto: string;
}

interface State
  extends GetProductosSat,
    CrearProductoSat,
    ActualizarProductoSat {
  searching: boolean;
  saving: boolean;
}

const initialState: State = {
  productosSat: null,
  name: null,
  claveProducto: null,
  id: null,
  searching: false,
  saving: false
};

export default function productosSatReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case actionTypes.CLEAR_PRODUCTOS_SAT: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS}_PENDING`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS}_REJECTED`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS_CUSTOM}_PENDING`: {
      return {
        productosSat: null,
        searching: true
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS_CUSTOM}_REJECTED`: {
      return {
        productosSat: null,
        searching: false
      };
    }
    case `${actionTypes.GET_PRODUCTOS_SAT_ACTIVOS_CUSTOM}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data,
        searching: false
      };
    }
    case `${actionTypes.POST_PRODUCTO_SAT}_PENDING`: {
      return {
        productosSat: null,
        saving: true
      };
    }
    case `${actionTypes.POST_PRODUCTO_SAT}_REJECTED`: {
      return {
        productosSat: null,
        saving: false
      };
    }
    case `${actionTypes.POST_PRODUCTO_SAT}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data
          ? [action.payload.data]
          : state.productosSat || [],
        name: null,
        claveProducto: null,
        id: action.payload.data ? action.payload.data.id : null,
        saving: false
      };
    }
    case `${actionTypes.PUT_PRODUCTO_SAT}_PENDING`: {
      return {
        productosSat: null,
        saving: true
      };
    }
    case `${actionTypes.PUT_PRODUCTO_SAT}_REJECTED`: {
      return {
        productosSat: action.payload.data,
        saving: false
      };
    }
    case `${actionTypes.PUT_PRODUCTO_SAT}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data
          ? [action.payload.data]
          : state.productosSat || [],
        name: null,
        claveProducto: null,
        id: action.payload.data ? action.payload.data.id : null,
        saving: false
      };
    }
    case `${actionTypes.DELETE_PRODUCTO_SAT}_PENDING`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.DELETE_PRODUCTO_SAT}_REJECTED`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.DELETE_PRODUCTO_SAT}_FULFILLED`: {
      successNotification("Producto SAT eliminado correctamente");
      return {
        ...state
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_CLAVE_DUPLICADOS}_PENDING`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_CLAVE_DUPLICADOS}_REJECTED`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_CLAVE_DUPLICADOS}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_DUPLICADOS}_PENDING`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_DUPLICADOS}_REJECTED`: {
      return {
        productosSat: null
      };
    }
    case `${actionTypes.GET_PRODUCTOS_NOMBRE_DUPLICADOS}_FULFILLED`: {
      return {
        ...state,
        productosSat: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}
