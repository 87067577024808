import React from "react";
import IInputProps from "./IInput.props";
import IInputState from "./IInput.state";

export default class IInput<
  P extends IInputProps,
  S extends IInputState
> extends React.Component<P, S> {
  public static get whoIAm() {
    return "IInput";
  }

  triggerInput(): void {
    if (this.props.triggerGetter) {
      this.props.triggerGetter(() => this.state.value);
    }

    if (this.props.triggerSetter) {
      this.props.triggerSetter((value: any) => {
        this.props.setter(this.props.name, value);
        this.setState({ value });
      });
    }
  }

  btsClassName = () => {
    const p = this.props;
    let className: string = "";
    className =
      p.xCol || p.xCol == 0 ? className.concat(`col-${p.xCol} `) : className;
    className =
      p.sCol || p.sCol == 0 ? className.concat(`col-sm-${p.sCol} `) : className;
    className =
      p.mCol || p.mCol == 0 ? className.concat(`col-md-${p.mCol} `) : className;
    className =
      p.lCol || p.lCol == 0 ? className.concat(`col-lg-${p.lCol} `) : className;
    className =
      p.xOff || p.xOff == 0 ? className.concat(`offset-${p.xOff} `) : className;
    className =
      p.sOff || p.sOff == 0
        ? className.concat(`offset-sm-${p.sOff} `)
        : className;
    className =
      p.mOff || p.mOff == 0
        ? className.concat(`offset-md-${p.mOff} `)
        : className;
    className =
      p.lOff || p.lOff == 0
        ? className.concat(`offset-lg-${p.lOff} `)
        : className;

    className = className || "col ";
    return p.classCol ? className + `${p.classCol}` : className;
  };

  render() {
    return <>No renderice esta clase directamente; error de desarrollo</>;
  }
}
